import React, { useEffect, useState } from 'react';
import LoginForm from '../authBlocks/loginForm';
import { Link } from 'react-router-dom';
import GoogleLoginComponent from '../../utils/googleLogin';

function LoginSection({ email, setEmail, password, loading, setPassword, rememberMe, setRememberMe, handleLogin, themeMode}) {
    const eventId = localStorage.getItem('eventId')
    return (
        <div className="w-full flex justify-center dark:bg-[#191a1b] lg:w-1/3 bg-white">
            <div className=' w-11/12 max-w-[700px] px-10 py-5 rounded-3xl bg-white dark:bg-[#191a1b]'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={themeMode === 'light' ? "/title-image.png" : "/title-image.png"} style={{ width: '200px', marginBottom: '2px' }} />
                    {/* <h1 className='!text-[#4535C1]' style={{ fontFamily: 'Sequel100Black', fontSize: '1.25rem', color: '#6e6e6e', marginTop: '4px', margin: 0 }}>
                        template
                    </h1> */}
                </div>
                <p className='font-medium text-lg text-gray-500 mt-4'></p>
                <LoginForm
                    email={email}
                    setEmail={setEmail}
                    loading={loading}
                    password={password}
                    setPassword={setPassword}
                    rememberMe={rememberMe}
                    setRememberMe={setRememberMe}
                    handleLogin={handleLogin}
                />
                <div className="mt-12 flex justify-center google-login-component">
                    {/* <button onClick={() => googleSignup()}>Login with Google</button> */}
                    {/* <GoogleButton
                        label='Login with google'
                        // onClick={googleSignup}
                    /> */}
                    <GoogleLoginComponent userRole={''} label={"Login with google"}/>
                </div>
                <div className='mt-8 flex justify-center items-center'>
                    <p className='font-medium text-base'>Don't have an account?</p>
                    <Link to={eventId ? "/signup-select-character" : "/signup-as"} className='ml-2 font-medium text-base text-[#4535C1]'>Sign up</Link>
                </div>
                {/* New section for Terms, Privacy, and Support */}
                <div className='mt-4 flex justify-center items-center'>
                    <Link to="/terms-of-service" className='font-small text-xs mx-2 text-gray-500 underline'>Terms of Service</Link>
                    <Link to="/privacy-policy" className='font-small text-xs mx-2 text-gray-500 underline'>Privacy Policy</Link>
                    <Link to="/support" className='font-small text-xs mx-2 text-gray-500 underline'>Support</Link>
                </div>
            </div>
        </div>
    );
}

export default LoginSection;
