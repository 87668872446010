import React from 'react'

const YoutubePreview = ({link}) => {
    const embedUrl = link.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');

    return (
    
            <iframe
                className="youtube-iframe"
                title="Youtube Content"
                src={embedUrl}
                // frameBorder="0"
                allowTransparency="true"
                allow="encrypted-media">
            </iframe>
  
    );
}

export default YoutubePreview
