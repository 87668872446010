const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const getReferralCode = async (currentPage, searchTerm) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/get-code-list?page=${currentPage}&limit=10&keyword=${searchTerm}`, {
            method: 'GET',
            headers: {
                 'Content-Type': 'application/json',  
                 'Authorization': localStorage.getItem('authKey')
            }
        });

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            alert(errorData?.message || 'Unknown error occurred');
            return;
        }

        // Parse the response data
        const responseData = await response.json();
        return responseData;

    } catch (error) {
        console.error('Error during get referral code:', error);
    }
};

export const getSingleReferralCode = async (signupReferralCodeId) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/get-code-list?signupReferralCodeId=${signupReferralCodeId}`, {
            method: 'GET',
            headers: {
                 'Content-Type': 'application/json',  
                 'Authorization': localStorage.getItem('authKey')
            }
        });

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            alert(errorData?.message || 'Unknown error occurred');
            return;
        }

        // Parse the response data
        const responseData = await response.json();
        return responseData;

    } catch (error) {
        alert('Error during get referral code: ' + error.message);
        console.error('Error during get referral code:', error);
    }
};

export const addReferralCode = async (referralData, fieldData) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/create-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey') || ''
            },
            body: JSON.stringify({
                adminSecureCode: fieldData,
                referralCode: referralData.referralCode,
                expiryDate: referralData.expiryDate,
                limit: referralData.limit,
                status: referralData.status
            })
        });

        const responseData = await response.json();

        if (response.ok) {
            alert(responseData?.message)
            return responseData;
        } else {
            alert(responseData?.message || 'An error occurred while adding the referral code.');
            return null;
        }
    } catch (error) {
        // Log the error message for debugging purposes
        alert('Error during add referral code: ' + error.message);
        return null;
    }
};


export const updateReferralCode = async (referralId, referralData, fieldData) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/update-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                signupReferralCodeId: referralId,
                adminSecureCode: fieldData,
                referralCode: referralData.referralCode,
                expiryDate: referralData.expiryDate,
                limit: referralData.limit,
                status: referralData.status,
            })
        });

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            alert(errorData?.message || 'Unknown error occurred');
            return;
        }

        // Parse the response data
        const responseData = await response.json();
        alert(responseData?.message)
        return responseData;

    } catch (error) {
        alert('Error during update referral code: ' + error.message);
        console.error('Error during update referral code:', error);
    }
};


export const checkReferralCode = async (code, checkedFor) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/check-available-code?checkedFor=${checkedFor}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                referralCode: code
            })
        });

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            return errorData
        }

        // Parse the response data
        const responseData = await response.json();
        return responseData;

    } catch (error) {
        alert('Error during add referral code: ' + error.message);
    }
}

export const deleteReferralCode = async (referralId, fieldData) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/signupRefferalCode/delete-code`, {
            method: 'POST',
            headers: {
                 'Content-Type': 'application/json',  
                 'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                signupReferralCodeId: referralId,
                adminSecureCode: fieldData
            })
        });
        if (!response.ok) {
            const errorData = await response.json();
            alert(errorData?.message || 'Unknown error occurred');
            return;
        }
        const responseData = await response.json();
        alert(responseData?.message)
        return responseData;

    } catch (error) {
        console.error('Error during get referral code:', error);
    }
};