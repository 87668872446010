
export const calculateWidth = (percentage) => {
    const screenWidth = window.innerWidth;
    return (screenWidth * percentage) / 100;
};



export const filterEventsByTimePeriodGraph = (events, timePeriod) => {
    const now = new Date();
    let filteredEvents = [];

    switch (timePeriod) {
        case 'month':
            filteredEvents = events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 1)));
            break;
        case '3months':
            filteredEvents = events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 3)));
            break;
        case '6months':
            filteredEvents = events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 6)));
            break;
        case 'year':
            filteredEvents = events.filter(event => new Date(event.eventTime) > new Date(now.setFullYear(now.getFullYear() - 1)));
            break;
        default:
            filteredEvents = [...events];
            break;
    }
    return filteredEvents;
};

export const processEventsData = (events, timePeriod) => {
    const filteredEvents = filterEventsByTimePeriodGraph(events, timePeriod);
    return filteredEvents.map(event => ({
        name: event.eventName,
        date: new Date(event.eventTime).toLocaleDateString(),
        maxParticipants: event.eventMaxBookings,
        preBookings: event.eventPreBookings,
        verifiedBookings: event.eventVerifiedBookings,
    }));
};

export const performDocumentSearch = async (searchTerm, PORT_BACKEND_URL_SRC, setSearchResults, selectedEvents, selectedSingleId, currentPage) => {
    // if (!searchTerm) {
    //     setSearchResults([]); // Clear results if no search term
    //     return;
    // }
    const allEventsId = selectedEvents.length > 0 ? selectedEvents : selectedSingleId
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
              keyword: searchTerm,
              eventIdList :allEventsId,
              page: currentPage,
              limit: 10
            })
        });
        const data = await response.json();
        if (data.success) {
            setSearchResults(data); // Update the searchResults state
        } else {
            console.error('Search failed:', data.message);
            setSearchResults([]); // Clear results on failure
        }
    } catch (error) {
        console.error('Error performing search:', error);
        setSearchResults([]); // Clear results on error
    }
};

export const fetchTopWords = async (PORT_BACKEND_URL_SRC, userId, timeFilter, setTopWords) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/get-top-words-for-events?eventId=${userId}&timeFilter=${timeFilter}`);
        const data = await response.json();
        setTopWords(data.topWords);
    } catch (error) {
        console.error('Error fetching top words:', error);
    }
};
// user-dashboard-event-analytics-utility.js
export const fetchForms = async (searchTerm, PORT_BACKEND_URL_SRC, currentUser) => {
    try {
      const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/search/forms?query=${searchTerm}&userId=${currentUser}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (data.success) {
        return data.results; // Return the search results
      } else {
        console.error('Search failed:', data.message);
        return []; // Return empty array on failure
      }
    } catch (error) {
      console.error('Error performing search:', error);
      return []; // Return empty array on error
    }
  };
  
  export const fetchTranscripts = async (PORT_BACKEND_URL_SRC, transcriptId) => {
    try {
      const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/search/transcripts?chatTranscriptId=${transcriptId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('authKey') },
      });
      const data = await response.json();
      if (data.success) {
        return data; // Return the search results
      } else {
        console.error('Search failed:', data.message);
        return []; // Return empty array on failure
      }
    } catch (error) {
      console.error('Error performing search:', error);
      return []; // Return empty array on error
    }
  };
  