const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const getAvatar = async () => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/get-default-model`, {
            method: 'GET',
            headers: {
                 'Authorization': localStorage.getItem('authKey')
            }
        });

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            alert(errorData?.message);
            return;
        }

        // Parse the response data
        const responseData = await response.json();
        return responseData;

    } catch (error) {
        console.error('Error :', error);
    }
};