import React, { useState } from 'react'
import InputField from '../miscBlocks/inputField'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import { useNavigate } from 'react-router-dom';
import LoadingData from '../loading/Loading';
import { handleSpotifyStatusCode } from '../../statuscodemanage/checkSpotifyStatus';


const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripe = require('stripe')(stripeKey);

const UserAddCardDetails = ({ setAccountPopup, userId }) => {
    const navigate = useNavigate()
    const [cardDetails, setCardDetails] = useState({
        name: '',
        number: '',
        month: '',
        year: '',
        cvc: '',
        expiryDt: ''
    })
    const [loading, setLoading] = useState(false)
    const eventId = localStorage.getItem('eventId')
    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'expiryDt' && value.length > 4) {
            // If the length of the value exceeds 5 characters (including '/'), slice it
            const truncatedValue = value.slice(0, 5);
            setCardDetails({ ...cardDetails, [name]: truncatedValue });
        } else {
            setCardDetails({ ...cardDetails, [name]: value });
        }
    };

    const addCard = async (e) => {
        e.preventDefault()
        setLoading(true)
        const [selectedMonth, selectedYear] = cardDetails.expiryDt.split('/');
        const token = await stripe.tokens.create({
            card: {
                name: cardDetails.name,
                number: cardDetails.number,
                exp_month: selectedMonth,
                exp_year: '20' + selectedYear,
                cvc: cardDetails.cvc,
            },
        });
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/create-card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cardToken: token.id,
                    userId: userId,
                    name: cardDetails?.name
                }),
            });
            const data = await response.json()
            // handleSpotifyStatusCode(response.status, data.message)
            if (response.ok) {
                // alert(data.message)
                localStorage.setItem('authKey', data?.data?.token)
                window.location.reload()
                setLoading(false)
            }

        } catch (error) {
            console.error('Error sending feedback:', error);
            return { success: false, message: 'An error occurred while sending feedback.' };
        }
    }

    const onChangeExp = (e) => {
        var value = e.target.value;
        if(value.length > 5){
            value = value.slice(0,-1)
        }
        setCardDetails({
            ...cardDetails,
            expiryDt: value
        });
    };

    const expriy_format = (value) => {
        const expdate = value;
        const expDateFormatter =
            expdate.replace(/\//g, "").substring(0, 2) +
            (expdate.length > 2 ? "/" : "") +
            expdate.replace(/\//g, "").substring(2, 4);
        return expDateFormatter;
    };
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-[150000] justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-md h-screen">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold dark:text-white text-gray-900">
                            Add Card
                        </h3>
                        <button type="button" onClick={() => setAccountPopup(false)} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form className="p-4 pt-1" onSubmit={addCard}>
                        <div className="space-y-4" >
                            <InputField
                                label="Card Number"
                                type="number"
                                name='number'
                                placeholder="Enter your Card Number"
                                value={cardDetails.number}
                                onChange={handleChange}
                            />
                            <InputField
                                label="Card Holder Name"
                                type="text"
                                name='name'
                                value={cardDetails.name}
                                placeholder="Enter your Card Holder Name"
                                onChange={handleChange}
                            />
                            <InputField
                                label="CVC"
                                type="password"
                                name='cvc'
                                value={cardDetails.cvc}
                                placeholder="Enter your Cvc"
                                onChange={handleChange}
                            />
                            <div className="card mt-5 mb-5">
                                <label className='text-lg font-medium'>Expiry</label>
                                {/*<input
                        type="month"
                        id="start"
                        name="start"
                        // value={`${cardDetails.year}-${cardDetails.month}`}
                        className='focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        onChange={handleInputChange}
                    />*/}
                                <input
                                    type="text"
                                    name="expiryData"
                                    className="focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent"
                                    placeholder="mm / yy"
                                    onChange={onChangeExp}
                                    value={expriy_format(cardDetails.expiryDt)}
                                />
                            </div>
                        </div>
                        <div className="mt-6">
                            <button type="submit" className="w-full flex justify-center animated-btn"><span className='text'>{loading ? <LoadingData /> : "Add"}</span></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserAddCardDetails
