import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend'
import RotatingGeoCylinder from '../../../components/geometries/others/rotatingGeoCylinder'
import RotatingGeoCylinderInverse from '../../../components/geometries/others/rotatingGeoCylinderInverse'

const UserSignupAs = () => {
    const navigate = useNavigate()
    const handleRole = async (userRole) => {
        // if(localStorage.getItem('googleId')){
        //     const response = await fetch(`${PORT_BACKEND_URL_SRC}/social-signIn`, {
        //         method: 'POST',
        //         headers: {
        //           'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ 
        //             email: localStorage.getItem('email'),
        //             firstName : localStorage.getItem('firstName'),
        //             lastName : localStorage.getItem('lastName'),
        //             googleId: localStorage.getItem('googleId'),
        //             role: userRole ? "regular" : "creator" }),
        //       });
        
        //       const data = await response.json();
        //       if (response?.status === 256) {
        //         localStorage.setItem('authKey', data?.data?.accessToken);
        //         localStorage.setItem('userId', data?.data?.userId);
        //         navigate('/user-dashboard-create-settings');
        //       }
        //       else{
        //         alert(data?.message)
        //       }
        // }
        // else{
        //     navigate('/user-signup', { state: { isRegularUser: userRole } })
        // }
        navigate('/signup-select-character', { state: { isRegularUser: userRole } })

    }
    return (
        <div className="">
            <div className="">
                <div className="dark:bg-[#191a1b] ">
                    <div className="p-4 flex flex-col gap-2 rounded-3xl dark:bg-[#191a1b] border-0 border-white">
                        <div className="event-title-container">
                            <h1 className="event-name text-center">Get Started</h1>
                        </div>
                        <div className='flex flex-col mt-4 lg:mt-12 lg:flex-row items-center justify-center gap-10 lg:gap-20'>
                            <div className='card !py-6 flex flex-col items-center w-[320px] sm:w-[440px]'>
                                {/* <img src='/images/creator.jpg' className='w-1/2' alt='user' /> */}
                                <RotatingGeoCylinder />
                                <div className='font-bold text-2xl text-center mt-12'>Creator</div>
                                <div className='text-lg text-center mt-6'>Cheers to a new beginning!</div>
                                {/* <button className='bg-black text-white px-6 mt-6 py-2 rounded-full mb-4 dark:text-black  dark:bg-white dark:opacity-50' onClick={() => handleRole(false)}>Sign up</button> */}
                                <button className='px-8 !w-fit mt-6 py-3 animated-btn' onClick={() => handleRole(false)}><span className='text'>Sign up</span></button>
                            </div>
                            <div className='card flex !py-6 flex-col items-center w-[320px] sm:w-[440px]'>
                                {/* <img src='/images/regular_user.jpg' className='w-1/2' alt='user' /> */}
                                <RotatingGeoCylinderInverse/>
                                <div className='font-bold text-2xl text-center mt-12'>Audience</div>
                                <div className=' text-lg text-center mt-6'>Ready to have some fun?</div>
                                {/* <button className='bg-black text-white px-6  mt-6 py-2 rounded-full mb-4 dark:text-black  dark:bg-white dark:opacity-50' onClick={() => handleRole(true)}>Sign up</button> */}
                                <button className='px-8 !w-fit mt-6 py-3 animated-btn' onClick={() => handleRole(true)}><span className='text'>Sign up</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div> */}
            </div>
        </div>
    )
}

export default UserSignupAs
