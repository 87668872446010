import '../../../styles/sharedStyle.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getUserPhoneByToken } from '../../../api/phoneAPI';
import VerificationSMS from '../../../components/authBlocks/verificationSMS';// Import your verification component
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');


function UserVerifySMS() {
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userPhoneNumber, setUserPhoneNumber] = useState(null); // State to store 
    const navigate = useNavigate();
    const location = useLocation();
    const { token } = useParams(); // Retrieve the token from the URL

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     // Fetch the user's phone number using the token when the component mounts
    //     getUserPhoneByToken(token)
    //         .then(phoneNumber => {
    //             setUserPhoneNumber(phoneNumber);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching user phone number:', error);
    //         });      
    // }, [token]); -- CC
    // Dependency array with token
    // Log eventId and redirectTo on component mount and whenever location.state changes
    useEffect(() => {
        const eventId = localStorage.getItem('eventId'); // Fetching eventId from localStorage
        const redirectTo = localStorage.getItem('redirectTo'); // Fetching redirectTo from localStorage
    }, [location]);


    const handleResendSMS = async () => {
        try {
            if (!token) {
                alert('Verification token is not available. Cannot resend SMS.');
                return;
            }

            const response = await fetch(`${PORT_BACKEND_URL_SRC}/resend-sms-temp-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });

            if (!response.ok) {
                throw new Error('Failed to resend SMS to temporary user');
            }

            alert('SMS verification code has been resent to temporary user.');
        } catch (error) {
            console.error('Error during SMS resend:', error);
            alert(error.message);
        }
    };

    const handleVerification = () => {
        setIsLoading(true);
        const phoneNumber = localStorage.getItem('phoneNumber'); // Retrieve phoneNumber from Local Storage
        fetch(`${PORT_BACKEND_URL_SRC}/verify-sms/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                code: verificationCode,
                phoneNumber // Include phoneNumber in the request body
            })
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                alert(errorData.message)
            }
            return response.json();
        })
            .then(data => {
                if (data.success) {
                    const emailVerificationToken = localStorage.getItem('emailVerificationToken');
                    navigate(`/verify-email/${emailVerificationToken}`
                        // state: { redirectTo: location.state.redirectTo, eventId: location.state.eventId }
                    );
                } else {
                    alert('Verification failed:', data.message);
                }
            })
            .catch(error => {
                console.error('Error during verification:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="flex full-screen">
            <div className="m-auto">
                <VerificationSMS
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    handleVerification={() => handleVerification()}
                    handleResendSMS={handleResendSMS} // Pass the resend function
                    isLoading={isLoading}
                />

            </div>
        </div>
    );
}

export default UserVerifySMS;

