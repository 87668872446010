// PasswordResetForm.js
import React from 'react';
import DetailBackButton from '../events/detailSection/detailBackButton';
import DetailEventTitle from '../events/detailSection/detailEventTitle';

function PasswordResetForm({ password, setPassword, confirmPassword, setConfirmPassword, handleResetPassword }) {
    return (
        <div className='w-11/12 max-w-[700px] dark:bg-[#191a1b] px-10 py-20 rounded-3xl bg-white border-0 border-white'>
               <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>

            <div className="card">
                <DetailEventTitle eventName={"Reset Password"} />
            </div>

            <div className="card">
                <div className='flex flex-col'>
                    {/* <label className='text-lg font-medium'>New Password</label> */}
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        placeholder="Enter your new password"
                    />
                </div>
                </div>
                <div className="card">
                <div className='flex flex-col mt-4'>
                    {/* <label className='text-lg font-medium'>Confirm Password</label> */}
                    <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        placeholder="Confirm your new password"
                    />
                </div>
                </div>
                <div className='mt-8 flex flex-col gap-y-4'>
                    <button
                        onClick={handleResetPassword}
                        className='active:scale-[.98] dark:text-black  dark:bg-white dark:opacity-50 active:duration-75 transition-all hover:scale-[1.01]  ease-in-out transform py-4 bg-black rounded-xl text-white font-bold text-lg'>
                        Reset Password
                    </button>
                </div>
            {/* </div> */}
        </div>
    );
}

export default PasswordResetForm;
