import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/* shared pages and home */
import Home from './pages/home';
/* user  > auth*/
import TermsOfService from './pages/support/terms-of-service';
import PrivacyPolicy from './pages/support/privacy-policy';
import Support from './pages/support/support';
import UserThankYouFeedback from './pages/support/user-thank-you-feedback';

import UserDisplayEmailConfirmationCode from './pages/user/auth/user-display-email-confirmation-code';
import UserVerifySMSLogin from './pages/user/auth/user-verify-sms-login';
import UserVerifyEmail from './pages/user/auth/user-verify-email';
import UserVerifySMS from './pages/user/auth/user-verify-sms';
import UserSignUp from './pages/user/auth/user-signup';
import UserForgetPassword from './pages/user/auth/user-forget-password';
import UserResetPassword from './pages/user/auth/user-reset-password';
import UserConfirmationResetPassword from './pages/user/auth/user-confirmation-reset-password';
import UserConfirmationSignUp from './pages/user/auth/user-confirmation-signup';
import UserSpotifyLogin from './pages/user/auth/user-spotify-login.js';
/* user  > booking*/
import UserChatExperience from './pages/user/bookingExperience/user-chat-experience.js';
import UserEventForm from './pages/user/bookingExperience/user-event-form.js';
/* user  > dashboard */
import UserDashboardCreate from './pages/user/dashboardCreate/user-dashboard-create';
import UserDashboardEventCreate from './pages/user/dashboardCreate/user-dashboard-event-create';
import UserDashboardEventEdit from './pages/user/dashboardCreate/user-dashboard-event-edit';
import UserDashboardEventDetails from './pages/user/dashboardCreate/user-dashboard-event-details';
import UserDashboardEventShare from './pages/user/dashboardCreate/user-dashboard-event-share';
import UserDashboardEventAnalytics from './pages/user/dashboardCreate/user-dashboard-event-analytics';
import UserDashboardEventNewsletter from './pages/user/dashboardCreate/user-dashboard-event-newsletter';

import QRCodeScanner from './pages/user/dashboardCreate/qr-code-experience.js';
import UserSpotifyCallback from './pages/user/auth/user-spotify-callback';
import Userregulardashboard from './pages/user/dashboardCreate/user-regular-dashboard.js';
import UserDashboardCreateSettings from './pages/user/dashboardCreate/user-dashboard-create-settings.js';
import UserPaymentIntegration from './pages/user/auth/user-payment-integration.js';
import UserRetrieveAccount from './pages/user/auth/user-retrieve-account.js';
import UserAddCard from './pages/user/auth/user-add-card.js';
import UserBuyTicket from './pages/regularuser/user-buy-ticket.js';
import UserCheckout from './pages/regularuser/user-checkout.js';
import UserDashboardBook from './pages/regularuser/user-dashboard-book.js';
import EventRegularUserDetails from './pages/regularuser/event-regular-user-details.js';
import UserDetailsScan from './pages/regularuser/user-details-scan.js';
import SpotifyPreview from './spotifyPreview/spotifyPreview.js';
import FeatureEventConfirm from './pages/publicpages/feature-event-confirm.js';
import UserFreeChat from './pages/user/freeChat/user-free-chat.js';
import AllReferralCode from './referralCode/all-referral-code.js';
import AddEditReferralCode from './referralCode/add-edit-referral-code.js';
import UserSignupAs from './pages/user/auth/user-signup-as.js';
import GuestEmail from './pages/guestuser/GuestEmail.js';
import GuestVerifyEmail from './pages/guestuser/GuestVerifyEmail.js';
import GuestBooking from './pages/guestuser/GuestBooking.js';
import ThankYou from './pages/guestuser/ThankYou.js';
import GuestBookingDetails from './pages/guestuser/GuestBookingDetails.js';
import UserDiscovery from './pages/regularuser/user-discovery.js';
import EventDetails from './pages/regularuser/event-details.js';
import SubscriptionPlan from './pages/regularuser/subscription-plan.js';
import Theme from './Theme.js';
import UserPlanTransactionDetails from './pages/regularuser/user-transaction-details.js';
import UserSignupSelectCharacter from './pages/user/auth/user-signup-select-character.js';
import UserChangeCharacter from './pages/regularuser/user-change-character.js';
import { useState } from 'react';

function App() {
  const [themeMode, setThemeMode] = useState('light')
  return (
    <>
      <Router>
        <Routes>

          <Route path="/" element={<Home themeMode={themeMode}/>} />
          <Route path="/verify-sms/:token" element={<UserVerifySMS />} />
          <Route path="/get-user-phone-by-token/:token" element={<UserVerifySMS />} />
          <Route path="/verify-email/:token" element={<UserVerifyEmail />} />
          <Route path="/verify-login" element={<UserVerifySMSLogin />} />
          <Route path="/resend-sms" element={<UserVerifySMS />} />

          <Route path="/resend-sms-temp-user" element={<UserVerifySMSLogin />} />
          <Route path="/resend-email-temp-user" element={<UserVerifyEmail />} />
          <Route path="/get-user-phone/:userId" element={<UserVerifySMSLogin />} />
          <Route path="/user-display-email-confirmation-code/:token" element={<UserDisplayEmailConfirmationCode />} />

          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/support" element={<Support />} />
          <Route path="/user-thank-you-feedback" element={<UserThankYouFeedback />} />

          <Route path='/signup-as' element={<UserSignupAs />} />
          <Route path='/signup-select-character' element={<UserSignupSelectCharacter />} />
          <Route path="/user-signup" element={<UserSignUp />} />
          <Route path="/user-retrieve-account" element={<UserRetrieveAccount />} />
          <Route path="/stripe-connect" element={<UserPaymentIntegration />} />
          <Route path="/user-verify-sms" element={<UserVerifySMS />} />
          <Route path="/user-verify-email" element={<UserVerifyEmail />} />
          <Route path="/user-forget-password" element={< UserForgetPassword />} />
          <Route path="/user-reset/:token" element={< UserResetPassword />} />
          <Route path="/user-confirmation-reset-password" element={< UserConfirmationResetPassword />} />
          <Route path="/user-confirmation-sign-up" element={< UserConfirmationSignUp />} />

          <Route path="/creator-event-list" element={<UserDashboardCreate />} />
          <Route path="/user-dashboard-create" element={<UserDashboardCreate />} />
          <Route path="/user-dashboard-event-create" element={<UserDashboardEventCreate />} />
          <Route path="/current-user" element={<UserDashboardCreate />} />
          <Route path="/user-dashboard-event-edit/:eventId" element={<UserDashboardEventEdit />} />
          <Route path="/user-dashboard-create-settings" element={<UserDashboardCreateSettings/>} />

          <Route path="/user-dashboard-event-details/:eventId" element={<UserDashboardEventDetails />} />
          {/* <Route path="/event-share/:eventId" element={<UserDashboardEventShare />} /> */}
          <Route path="/user-dashboard-event-analytics" element={<UserDashboardEventAnalytics />} />
          <Route path="/user-dashboard-event-newsletter" element={<UserDashboardEventNewsletter />} />

          <Route path="/user-rsvp/:eventId" element={<UserEventForm />} />
          <Route path="/user-chat-experience/:eventId" element={<UserChatExperience />} />
          <Route path="/user-chat-experience/" element={<UserChatExperience />} />
          <Route path="/user-dashboard-event-share/:eventId" element={<UserDashboardEventShare />} />

          <Route path="/other-dashboard" element={<Userregulardashboard />} />
          <Route path="/qr-code-experience" element={<QRCodeScanner />} />

          {/* New route for Spotify verification */}
          <Route path="/verify-spotify" element={<UserSignUp />} />
          <Route path="/user-spotify-callback" element={<UserSpotifyCallback />} />
          <Route path="/user-spotify-login" element={<UserSpotifyLogin />} />

          {/* New route for Free chat in creator */}
          <Route path="/user-free-chat" element={<UserFreeChat />} />

          <Route path='/change-character' element={<UserChangeCharacter />} />

          {/* New route Regular User */}
          <Route path="/user-add-card" element={<UserAddCard />} />
          <Route path="/user-buy-ticket" element={<UserBuyTicket />} />
          <Route path="/checkout" element={<UserCheckout />} />
          <Route path="/user-dashboard-book" element={<UserDashboardBook />} />
          <Route path="/user-dashboard-book-details/:eventId" element={<EventRegularUserDetails />} />
          <Route path="/user-discovery" element={<UserDiscovery />} />
          <Route path="/event-details" element={<EventDetails />} />
          <Route path="/subscription-plan" element={<SubscriptionPlan />} />
          <Route path="/transaction-details" element={<UserPlanTransactionDetails />} />

          {/* New route for Scanning User Details */}
          <Route path="/get-participant-details" element={<UserDetailsScan />} />

          {/* New route for feature event confirm */}
          <Route path="/featured-event-details/:eventId" element={<FeatureEventConfirm />} />

          {/* New route for Admin users for the Referral code */}
          <Route path="/get-all-referral-code" element={<AllReferralCode />} />
          <Route path="/add-referral-details" element={<AddEditReferralCode />} />
          <Route path="/edit-referral-details/:id" element={<AddEditReferralCode />} />

          {/* New route for Guest booking */}
          <Route path="/guest-email" element={<GuestEmail />} />
          <Route path="/verify-guest-email/:emailVerificationToken" element={<GuestVerifyEmail />} />
          <Route path="/guest-booking" element={<GuestBooking />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/booking-details" element={<GuestBookingDetails />} />
        </Routes>
      </Router>
      
      {/*<SpotifyPreview/>*/}
      <SpotifyPreview/>
      <Theme setThemeMode={setThemeMode}/>
    </>
  );
}

export default App;
