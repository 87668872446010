import { useGoogleLogin } from '@react-oauth/google'; // Import useGoogleLogin hook
import { useNavigate } from 'react-router-dom';
import { PORT_BACKEND_URL_SRC } from '../srcLocalPortReferenceBackend';
import GoogleButton from 'react-google-button';
import RetriveAccountPopup from '../components/popup/retriveAccountPopup';
import { useState } from 'react';

const GoogleLoginComponent = ({ userRole, label, character }) => {
  const [retriveAccountPopup, setRetriveAccountPopup] = useState({
    openPopUp: false,
    message: '',
    accountDetailPopUp: false
  })
  const [email, setEmail] = useState('')
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: "implicit",

    onSuccess: async (tokenResponse) => {
      const userInfoResponse = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });

      const userProfile = await userInfoResponse.json();

      const { email, given_name: firstName, family_name: lastName } = userProfile;

      const eventId = localStorage.getItem('eventId');
      const socialSignInUrl = eventId
        ? `${PORT_BACKEND_URL_SRC}/social-signIn?usedFor=SPOTIFY_AUTH_CHECK`
        : `${PORT_BACKEND_URL_SRC}/social-signIn`;

      const response = await fetch(socialSignInUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          googleId: userProfile?.sub,
          ...(!!userRole && { role: userRole }),
          ...(!!character && { defaultAvatarModel: character })
        }),
      });


      const data = await response.json();
      localStorage.setItem('authKey', data?.data?.accessToken);
      if (response?.status === 200) {
        localStorage.setItem('userId', data?.data?.userId);
        localStorage.setItem('flow', 'FAST_SIGNUP')
        if (data?.data?.role === 'creator') {
          navigate('/user-free-chat')
        }
        else {
          if (localStorage.getItem('eventId')) {
            navigate(`/user-chat-experience/${localStorage.getItem('eventId')}`)
          }
          else {
            navigate('/user-discovery')
          }
        }
      }

      else if(response?.status === 244){
        localStorage.setItem('userId', data?.data?.userId);
        const userConfirmed = window.confirm(data.message);
        
        // Proceed only if user confirms
        if (userConfirmed) {
          navigate('/user-spotify-login')
        }
        else{
          navigate('/user-buy-ticket')
        }
      }

      else if (response?.status === 256) {
        localStorage.setItem('userId', data?.data?.userId);
        localStorage.setItem('flow', 'FAST_SIGNUP')
        navigate('/user-dashboard-create-settings');
      }
      else if (response?.status === 256) {
        const userConfirmed = window.confirm(data?.message);

        // Proceed only if user confirms
        if (userConfirmed) {
          navigate('/user-spotify-login')
        }
        else {
          navigate('/user-buy-ticket')
        }
      }
      else if (response?.status === 267) {
        alert(data?.message)
        localStorage.setItem('flow', 'FAST_SIGNUP')
        localStorage.setItem('email', email)
        localStorage.setItem('firstName', firstName)
        localStorage.setItem('lastName', lastName)
        localStorage.setItem('googleId', userProfile?.sub)
        navigate('/signup-as');
      }
      else if (response?.status === 288) {
        setRetriveAccountPopup({
          ...retriveAccountPopup,
          openPopUp: true,
          message: data?.message
        });
        setEmail(data?.email)
      }
      else if (response?.status === 404) {
        alert(data?.message || 'You already have account please login')
      }
      else if (response?.status === 403) {
        alert(data?.message || 'Invalid account details')
      }
    },

    onError: (errorResponse) => {
      console.error("Login Failed:", errorResponse);
    },
  });

  return (
    // <button onClick={() => googleLogin()}>Login with Google</button>
    <>
      {retriveAccountPopup?.openPopUp && <RetriveAccountPopup email={email} retriveAccountPopup={retriveAccountPopup} setRetriveAccountPopup={setRetriveAccountPopup} />}
      <GoogleButton
        label={label}
        onClick={() => googleLogin()}
        className="!bg-orange-500"
      />
    </>
  );
};

export default GoogleLoginComponent;
