import React from 'react'

const UserAddAccountSection = ({handleSetDefault, loading, userRole,setCurrentBankDetails,userBankDetails, setAccountPopup, setDeleteCardPopup, userCardDetails}) => {
    const handleDeleteCard = async (arr) => {
        setDeleteCardPopup(true)
        setCurrentBankDetails(arr)
    }
    return (
        <div>
            <div className='card'>
                <div className='flex items-center'>
                    <div className="event-title-container">
                        <h1 className="text-md sm:text-xl font-bold md:event-name m-0">{userRole === 'creator' ? 'Account Details' : userRole === 'regular' && 'Card Details'}</h1>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <button
                            type='button'
                            className='addBank-button  !bg-[#686D76]'
                            // className='bg-black hover:bg-black text-white font-bold py-4 px-5 rounded-lg mr-6'
                            onClick={() => setAccountPopup(true)}
                        >
                            {userRole === 'creator' ? 'Add Bank Account' : userRole === 'regular' && 'Add Card'}
                        </button>
                    </div>
                </div>
                <hr className='mt-4' />
                {
                    userRole === 'creator' && userBankDetails?.map((arr) => {
                        return (
                            <div className='flex items-center mb-7 mt-10'>
                                <div className='w-20 h-20 mr-4'>
                                    <img src='/images/bank_image.png' alt='bank png' className='w-full h-full object-cover' />
                                </div>
                                <div className='flex flex-col md:flex-row md:flex-grow'>
                                    <div className='flex-grow mr-2'>
                                        <span className='text-gray-700  dark:text-white md:text-xl'>{arr.bank_name}</span>
                                        <span className='text-gray-400 font-semibold block pt-1  md:text-xl'>
                                            ********{arr.last4}
                                        </span>
                                    </div>
                                    <button
                                        className={` font-semibold py-3 px-7 rounded-lg ${arr.default_for_currency ? 'addBank-button !bg-[#686D76]' : 'text-white bg-[#373A40]'}`}
                                        onClick={() => handleSetDefault(arr)}
                                        disabled={arr.default_for_currency}
                                        style={{width: '144px'}}
                                    >
                                        {arr.default_for_currency ? 'Default' : 'Set Default'}
                                    </button>
                                    {!arr.default_for_currency && <button
                                        className='cancelform-button md:ml-3 mt-3 md:mt-0'
                                        onClick={() => handleDeleteCard(arr)}
                                        style={{width: '144px'}}
                                    >
                                        Delete
                                    </button>}
                                </div>
                            </div>
                        )
                    })
                }
                
                {
                    userRole === 'regular' && userCardDetails?.map((arr) => {
                        return (
                            <div className='flex items-center mb-7 mt-10'>
                                <div className='w-36 h-28 mr-4'>
                                    <img src='/images/credit-card-image.png' alt='bank png' className='w-full h-full' />
                                </div>
                                <div className='flex flex-col md:flex-row md:flex-grow w-1/2 sm:w-auto'>
                                    <div className='flex-grow mr-2'>
                                        <span className='text-gray-700  dark:text-white md:text-xl'>{arr.name}</span>
                                        <span className='text-gray-400 font-semibold block pt-1  md:text-xl'>
                                            ********{arr.last4}
                                        </span>
                                    </div>
                                    <button
                                        className={` font-semibold py-3 px-7 rounded-lg ${arr.is_default ? 'addBank-button !bg-[#686D76]' : 'text-white bg-[#373A40]'}`}
                                        onClick={() => handleSetDefault(arr)}
                                        disabled={arr.is_default}
                                        style={{width: '144px'}}
                                    >
                                        {arr.is_default ? 'Default' : 'Set Default'}
                                    </button>
                                    {!arr.is_default && <button
                                        className='cancelform-button ml-0 mt-3 md:mt-0 md:ml-3'
                                        onClick={() => handleDeleteCard(arr)}
                                        style={{width: '144px'}}
                                    >
                                        Delete
                                    </button>}
                                </div>
                            </div>
                        )
                    })
                }
                

            </div>
        </div>
    )
}

export default UserAddAccountSection