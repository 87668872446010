// import '../../../styles/sharedStyle.css';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BufferToBase64 from '../../../utils/bufferToBase64';
import { useParams } from 'react-router-dom';
import EventEditForm from '../../../components/events/eventEditForm';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
import { useFetchEventDetails, handleDelete, handleSubmit, validateQuestion, validateEventData } from './user-dashboard-event-edit-utility';
import UserStatusModel from '../../../statuscodemanage/user-status-model';


function UserDashboardEventEdit() {
    const [eventData, setEventData] = useFetchEventDetails();
    const [imageFile, setImageFile] = useState(null); // Initialize imageFile state
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [response, setResponse] = useState({})
    const { eventId } = useParams();
    // Inside the UserDashboardEventEdit function
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        if (eventData.eventImage) {
            // Assuming eventData.eventImage is the binary image data
            // const imageSrc = BufferToBase64(eventData.eventImage);
            setImagePreview(eventData.eventImage);
        }
    }, [eventData.eventImage]); // Depend on eventData.eventImage to update the preview



    // const handleImageDrop = (file) => {
    //     setImageFile(file);
    // };
    const handleImageDrop = (file) => {
        setImageFile(file);
        
        // Update the image preview to the newly selected file
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventData(prevState => {
            const newState = { ...prevState, [name]: value };
            return newState;
        });
    };

    // This function remains the same as you defined
    const handleLinkChange = (index, value) => {
        const updatedLinks = [...eventData.eventLinks];
        updatedLinks[index] = value;
        setEventData(prevState => ({
            ...prevState,
            eventLinks: updatedLinks
        }));
    };

    // This function remains the same as you defined
    // const handleQuestionChange = (index, value) => {
    //     const updatedQuestions = [...eventData.formQuestions];
    //     updatedQuestions[index] = value;
    //     setEventData(prevState => ({
    //         ...prevState,
    //         formQuestions: updatedQuestions
    //     }));
    // };

    const isValidSpotifyLink = (link) => {
        const spotifyLinkPattern = /^(https?:\/\/)?(www\.)?(spotify\.com|open\.spotify\.com)\/.+/;
        return spotifyLinkPattern.test(link);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const invalidLinks = eventData.eventLinks.filter(link => !isValidSpotifyLink(link));
        if (invalidLinks.length > 0) {
            alert("Please provide valid Spotify links. Invalid links: " + invalidLinks.join(', '));
            setLoading(false);
            return;
        }
        if(eventData?.eventMaxBookings < 1){
            alert("Please enter more than 1 Maximum Capacity")
            return
        }
        const passData = {
            eventName : eventData?.eventName,
            eventDescription : eventData?.eventDescription,
            eventTime : eventData?.eventTime,
            eventMaxBookings : eventData?.eventMaxBookings,
            eventLinks : eventData?.eventLinks
        }
        setLoading(true)
        await handleSubmit(passData,
            eventId, navigate, imageFile, validateEventData, validateQuestion, setResponse);
        setLoading(false)
    }
    // Wrap handleDelete to pass eventId and navigate
    const wrappedHandleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            await handleDelete(eventId, navigate);
        }
    };

    return (
        <div className="basic-container">
            <UserStatusModel eventResponse={response} />
            <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>
            <EventEditForm
                eventData={eventData}
                imagePreview={imagePreview} // Pass the image preview URL to the form
                setEventData={setEventData} // Make sure to pass setEventData
                handleChange={handleChange}
                handleLinkChange={handleLinkChange} // Pass this function to the form
                // handleQuestionChange={handleQuestionChange} // Pass this function to the form
                handleSubmit={handleFormSubmit}
                loading={loading}
                setImagePreview={setImagePreview}
                handleImageDrop={handleImageDrop}
                handleDelete={wrappedHandleDelete}
            />
        </div>
    );
};

export default UserDashboardEventEdit;
