import React, { useEffect, useState } from 'react'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import { FaCreditCard, FaRegCreditCard } from 'react-icons/fa';
import UserAddCardDetails from '../../components/popup/user-add-card-details';
import { useNavigate } from 'react-router-dom';
import LoadingData from '../../components/loading/Loading';
import PageLoader from '../../utils/PageLoader';

const SubscriptionPlan = () => {
    const navigate = useNavigate()
    const [apiData, setApiData] = useState([])
    const [selectedCard, setSelectedCard] = useState({})
    const [addCardPopup, setAddCardPopup] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCardChange = (e) => {
        const selectedIndex = e.target.value;
        const selectCard = apiData?.paymentMethodList?.find(item => item.last4 === selectedIndex)
        setSelectedCard(selectCard);
    };

    useEffect(() => {
        const getApiData = async () => {
            try {
                const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/fetch-subscription-plan-details`, {
                    method: 'GET',
                    headers: { Authorization: localStorage.getItem('authKey') }
                });
                const responseData = await response.json();
                if (response.status === 200 && responseData?.success) {
                    setApiData(responseData?.data);
                    const filteredData = responseData?.data?.paymentMethodList?.find(item => item.is_default)
                    setSelectedCard(filteredData)
                }
            } catch (error) {
                console.error(error);
            }
        }
        getApiData()
    }, [])

    const handleSubscribe = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/create-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify({
                    priceId: apiData?.subscriptionPlanDetails?.priceId,
                    cardId: selectedCard?.cardId
                })
            });

            const data = await response.json();
            if (response.ok) {
                setTimeout(() => {
                    setLoading(false)
                    navigate('/user-discovery')
                }, 3000)
            }
            else {
                alert(data?.message)
            }

        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <>
            {addCardPopup && <UserAddCardDetails userId={localStorage.getItem('userId')} setAccountPopup={setAddCardPopup} />}
            {apiData?.subscriptionPlanDetails ? <div className="min-h-screen bg-gray-50 p-6 flex items-center dark:bg-[#191a1b] justify-center">
                <div className="w-full max-w-3xl bg-white p-8 rounded-lg shadow-lg dark:bg-black dark:text-white">
                    <h1 className="text-3xl font-bold text-gray-800 dark:text-white mb-6">Subscription Plan</h1>

                    <div className="bg-blue-50 p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">{apiData?.subscriptionPlanDetails?.planName}</h2>
                        <p className="text-gray-600 mb-4">{apiData?.subscriptionPlanDetails?.planDescription}</p>
                        {apiData?.subscriptionPlanDetails?.planAmount && <div className="flex items-baseline">
                            <span className="text-4xl font-bold text-gray-900">${(apiData?.subscriptionPlanDetails?.planAmount / 100).toFixed(2)}</span>
                            <span className="text-xl text-gray-600 ml-2">/{apiData?.subscriptionPlanDetails?.planInterval}</span>
                        </div>}
                    </div>

                    <div className="mb-6">
                        <div className='flex justify-between items-center'>
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4 dark:text-white">Payment Methods</h2>
                            <button className='back-button !py-3 !px-4 !text-sm mt-2 text-white !bg-[#686D76]' onClick={() => setAddCardPopup(true)}>Add Card</button>
                        </div>
                        {apiData?.paymentMethodList?.length > 0 && <div className='card dark:bg-[#191a1b]'>
                            <label className="text-lg font-medium">
                                Select Card
                            </label>
                            <select
                                className="shadow border rounded dark:bg-black mt-2 dark:text-white dark:border-none p-4 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={handleCardChange}
                            >
                                {apiData?.paymentMethodList?.map((acctype, index) => <option className='capitalize py-3' key={index} value={acctype.last4}>
                                    {'********' + acctype.last4}
                                </option>)}
                            </select>
                        </div>}

                        {selectedCard ?
                            <div key={selectedCard?.cardId} className="flex items-center justify-between border dark:border-none p-4 dark:bg-[#191a1b] rounded-lg shadow-sm mb-4 dark:text-white bg-gray-50">
                                <div className="flex items-center">
                                    <FaCreditCard className="text-blue-500 text-2xl mr-3" />
                                    <div>
                                        <h3 className="text-lg font-semibold dark:text-white text-gray-800">{selectedCard?.name}</h3>
                                        <p className="text-gray-600 dark:text-white">**** **** **** {selectedCard?.last4}</p>
                                        {/* <p className="text-gray-500 text-sm">Status: {method.status}</p> */}
                                        <p className="text-gray-500 text-sm dark:text-white">Country origin: {selectedCard?.cardOrigin}</p>
                                    </div>
                                </div>
                            </div> : (
                                <div><p className="text-gray-500">No payment methods available please add card to subscribe the plan.</p>
                                    {/* <button className='back-button mt-2' onClick={() => setAddCardPopup(true)}>Add Card</button> */}
                                </div>
                            )}

                    </div>

                    <div className='flex gap-6 justify-center mt-12'>
                        {apiData?.paymentMethodList?.length > 0 && <button className='animated-btn !w-fit mb-8' onClick={handleSubscribe}><span className='text'>{loading ? <LoadingData/> : "Subscribe"}</span></button>}
                        <button className='deleteform-button'>Cancel</button>
                    </div>
                </div>
            </div> : <PageLoader/>}
        </>
    )
}

export default SubscriptionPlan;
