import { useAnimations, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
// import Monocolor from '../../../utils/monocolor';

const MaleModel = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale 2.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);
    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const MaleMono = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale 2 new texture.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);
    return (
        // <Monocolor scene={scene} />
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const FemaleModel = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale female default.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);

    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const FemaleMono = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale new texture.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);

    return (
        // <Monocolor scene={scene} />
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};


const UserSignupSelectCharacter = () => {
    const navigate = useNavigate()
    const location = useLocation();
    var userRole = location.state && location.state.isRegularUser
    if(userRole === null){
        userRole = true
    }
    console.log({userRole})
    const [selectedCharacter, setSelectedCharacter] = useState('MALE_OUTFIT')
    const [maleSelectModel, setMaleSelectModel] = useState('MALE_OUTFIT')
    const [femaleSelectModel, setFemaleSelectModel] = useState('FEMALE_OUTFIT')
    const renderCharacterModel = () => {
        switch (selectedCharacter) {
            case 'MALE_OUTFIT':
                return 'Male Normal'
            case 'MALE_UNIFORM':
                return 'Male Mono'
            case 'FEMALE_OUTFIT':
                return 'Female Normal'
            case 'FEMALE_UNIFORM':
                return 'Female Mono'
            default:
                return 'Male Normal' // Fallback to male model
        }
    };


    const handleSave = async () => {
        if (localStorage.getItem('googleId')) {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/social-signIn`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: localStorage.getItem('email'),
                    firstName: localStorage.getItem('firstName'),
                    lastName: localStorage.getItem('lastName'),
                    googleId: localStorage.getItem('googleId'),
                    role: userRole ? "regular" : "creator",
                    defaultAvatarModel: selectedCharacter
                }),
            });

            const data = await response.json();
            if (response?.status === 256) {
                localStorage.setItem('authKey', data?.data?.accessToken);
                localStorage.setItem('userId', data?.data?.userId);
                navigate('/user-dashboard-create-settings');
            }
            else {
                alert(data?.message)
            }
        }
        else {
            navigate('/user-signup', { state: { isRegularUser: userRole, character: selectedCharacter } })
        }
    }

    return (
        <div className="dark:bg-[#191a1b]">
            <div className="p-4 flex flex-col gap-2 rounded-3xl dark:bg-[#191a1b] border-0 border-white">
                <div className="event-title-container">
                    <h1 className="event-name text-center">Select Character</h1>
                    {/* <div className='text-center text-lg'>Our application use model for ai please select model from below</div> */}
                </div>
                {/* <div className='text-center text-lg'><span className='font-bold'> Selected</span> : {renderCharacterModel()}</div> */}
                <div className='flex flex-col lg:flex-row mt-4 lg:mt-12 items-center justify-center gap-10 lg:gap-20'>
                    <div>
                    <div className={`card cursor-pointer flex flex-col items-center w-[320px] sm:w-[440px] ${!selectedCharacter.includes("FEMALE") && 'outline outline-[#4535C1]'}`}>
                            {/* {maleSelectModel === 'MALE_OUTFIT' && <Canvas key={"123"} style={{ width: '230px', height: '300px', zIndex: 200 }}> */}
                            <Canvas style={{ width: '230px', height: '300px', zIndex: 200 }}>
                                <ambientLight color={0xcccccc} intensity={0.6} />
                                <directionalLight color={0xffffff} position={[10, 10, 5]} intensity={0.8} />
                                {maleSelectModel === 'MALE_OUTFIT' && <MaleModel />}
                                {maleSelectModel === 'MALE_UNIFORM' && <MaleMono />}
                            </Canvas>
                            {/* <div className='text-lg font-bold'>Male</div> */}
                        </div>
                        <div className='flex gap-5 justify-center'>
                            <div onClick={() => {
                                setSelectedCharacter('MALE_OUTFIT')
                                setMaleSelectModel('MALE_OUTFIT')
                            }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Outfit</span></div>
                            <div onClick={() => {
                                setSelectedCharacter('MALE_UNIFORM')
                                setMaleSelectModel('MALE_UNIFORM')
                            }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Uniform</span></div>
                        </div>
                    </div>
                    <div>
                    <div className={`card cursor-pointer flex flex-col items-center w-[320px] sm:w-[440px] ${selectedCharacter.includes("FEMALE") && 'outline outline-[#4535C1]'}`}>
                            <Canvas style={{ width: '230px', height: '300px', zIndex: 200 }}>
                                <ambientLight intensity={0.6} />
                                <directionalLight position={[10, 10, 5]} intensity={0.8} />
                                {femaleSelectModel === 'FEMALE_OUTFIT' && <FemaleModel />}
                                {femaleSelectModel === 'FEMALE_UNIFORM' && <FemaleMono />}
                            </Canvas>
                            {/* <div className='text-lg font-bold'>Female</div> */}
                        </div>
                        <div className='flex gap-5 justify-center'>
                            <div onClick={() => {
                                setSelectedCharacter('FEMALE_OUTFIT')
                                setFemaleSelectModel('FEMALE_OUTFIT')
                            }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Outfit</span></div>
                            <div onClick={() => {
                                setSelectedCharacter('FEMALE_UNIFORM')
                                setFemaleSelectModel('FEMALE_UNIFORM')
                            }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Uniform</span></div>
                        </div>
                    </div>
                </div>
                {/* <button className='text-white bg-black py-4 hover:bg-black focus:ring-4 focus:outline-none focus:bg-black font-semibold rounded-lg text-lg px-5 py-4 text-center dark:text-black m-auto mt-8  dark:bg-white dark:opacity-50 w-fit' onClick={handleSave}>Save</button> */}
                <button className='py-4 px-8 animated-btn m-auto mt-8 !w-fit' onClick={handleSave}><span className='text'>Save</span></button>
            </div>
        </div>
    )
}

export default UserSignupSelectCharacter
