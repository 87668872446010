import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const RotatingGeoCylinder = ({ colorTint }) => {
    const mountRef = useRef(null);

    useEffect(() => {
      const currentMountRef = mountRef.current;
        // Scene, camera, and renderer setup
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
        camera.position.z = 2; // Adjust as needed to frame the object

        // Renderer with antialiasing and transparent background
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        // Configure the renderer to use multisampling (if supported)
        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        renderer.setClearColor(0x000000, 0); // Second parameter is the alpha (transparency)
        mountRef.current.appendChild(renderer.domElement);

        // Cylinder geometry
        const geometry = new THREE.CylinderGeometry(0.6, 0.6, 2, 60, 1, false); // Adjust the radius, height, and segments as needed
        // Pearlescent Shader Material
        const material = new THREE.ShaderMaterial({
            uniforms: {
              colorTint: { value: new THREE.Color(colorTint) }
            },
            vertexShader: `
              varying vec3 vNormal;
              void main() {
                vNormal = normalize(normalMatrix * normal);
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `,
            fragmentShader: `
              uniform vec3 colorTint;
              varying vec3 vNormal;
              void main() {
                float intensity = pow(0.2 - dot(vNormal, vec3(0, 0, 1)), 2.0);
                // Adjust the base color for a bluish tone
                vec3 baseColor = vec3(0.1 - intensity, 0.2 - intensity, 0.9 + intensity);
                vec3 tintedColor = mix(baseColor, colorTint, 0.5);
                gl_FragColor = vec4(tintedColor, 1);
              }
            `
          });

        const cylinder = new THREE.Mesh(geometry, material);
        scene.add(cylinder);

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            cylinder.rotation.x += 0.01;
            cylinder.rotation.y += 0.01;
            renderer.render(scene, camera);
        };
        animate();

        // Handle window resize
        const onWindowResize = () => {
            camera.aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        };
        window.addEventListener('resize', onWindowResize);

        //Cleanup
        // return () => {
        //     window.removeEventListener('resize', onWindowResize);
        //     mountRef.current.removeChild(renderer.domElement);
        // };

        return () => {
          window.removeEventListener('resize', onWindowResize);
          if (currentMountRef && renderer.domElement) {
            currentMountRef.removeChild(renderer.domElement);
          }
          // Add any additional cleanup here, like disposing of Three.js objects
      };
      
    }, [colorTint]); // Add colorTint to the dependency array

    return (
        <div ref={mountRef} style={{ width: '100%', height: '200px' }} /> // Adjust height as needed
    );
};

export default RotatingGeoCylinder;
