// import React from 'react';
// import '../../../styles/detailIntro.css';
// import BufferToBase64 from '../../../utils/bufferToBase64';

// // const DetailEventImage = ({ eventImage, altText }) => {
// //   return (
// //     <div className="event-image-container">
// //       <img src={eventImage} alt={altText} className="event-image" />
// //     </div>
// //   );
// // };

// // export default DetailEventImage;

// const DetailEventImage = ({ event, altText }) => {
// //   let imageSrc = '';

// //   if (eventImage && eventImage.data) {
// //       const byteCharacters = String.fromCharCode.apply(null, eventImage.data);
// //       imageSrc = `data:image/jpeg;base64,${window.btoa(byteCharacters)}`;
// //   }
//  const imageSrc = BufferToBase64(event.eventImage);

//   return (
//       <div className="event-image-container">
//           {/* {imageSrc ? <img src={imageSrc} alt={altText} className="event-image" /> : <p>No image available</p>} */}
//           <img src={imageSrc} alt={event.eventName} />
//       </div>
//   );
// };


// export default DetailEventImage;

import React from 'react';
import '../../../styles/detailIntro.css';
import BufferToBase64 from '../../../utils/bufferToBase64';
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';

const DetailEventImage = ({ eventImage, altText }) => {
    // const imageSrc = BufferToBase64(eventImage);

    return (
        <div className="event-image-container">
            {eventImage ? <img src={PORT_BACKEND_URL_SRC + '/' + eventImage} alt={altText} className="event-image" /> : <p>No image available</p>}
        </div>
    );
};

export default DetailEventImage;