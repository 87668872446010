import React, { useState, useEffect } from 'react';
import InputField from '../../miscBlocks/inputField';
import '../../../styles/detail.css';
import QRCode from "react-qr-code";

const DynamicParticipantSearch = ({ eventParticipants, searchTerm, setSearchTerm, updateAttendance, setAttendantMessage, eventId, isScanning, handleScan, handleAddClick }) => {
    // const [searchTerm, setSearchTerm] = useState('');
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    useEffect(() => {
        if (searchTerm) {
            const lowerCaseTerm = searchTerm.toLowerCase();
            // Client Code
            //   const filtered = eventParticipants.filter(participant =>
            //     `${participant.firstName} ${participant.lastName}`.toLowerCase().includes(lowerCaseTerm)
            //   );
            const filtered = eventParticipants.filter(participant =>
                `${participant.participantInfo.firstName} ${participant.participantInfo.lastName}`.toLowerCase().includes(lowerCaseTerm)
            );
            setFilteredParticipants(filtered);
        }
        else{
            setFilteredParticipants(eventParticipants)
        }
    }, [searchTerm, eventParticipants]);

    const handleDisableClick = async (participant) => {
        await updateAttendance(participant._id, eventId, false);
    };
    const renderParticipants = () => {
        return (
            <div style={{ overflowY: 'auto', marginTop: '20px' }}>
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th>Participant</th>
                            <th>QR Code</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredParticipants?.map(participant => (
                            <tr key={participant._id} style={{ backgroundColor: participant.verified ? 'lightgreen' : '' }}>
                                <td>{`${participant.participantInfo.firstName} ${participant.participantInfo.lastName}`}</td>
                                <td className='flex justify-center'>
                                    <div
                                       className='creator-qr-code bg-white p-1'
                                        // style={{
                                        //     height: 'auto',
                                        //     maxWidth: '120px',
                                        //     width: '100%',
                                        //     marginTop: "20px",
                                        // }}
                                    >
                                        <QRCode
                                            size={256}
                                            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                            value={`https://planner-support-web-incipient-infotech.vercel.app/get-participant-details?participantId=${participant.participantId}&eventId=${participant.eventId}&bookingTransactionId=${participant?.bookingTransactionId}`}
                                            // value={`https://6ff5-150-129-206-197.ngrok-free.app/get-participant-details?participantId=${eventData.participantId}&eventId=${eventData.eventId}&bookingTransactionId=${eventData?.bookingTransactionDetails?._id}`}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {!participant.isConfirmedByCreator ? 
                                        <button onClick={() => handleAddClick(participant.participantId,participant.bookingTransactionId)} className="back-button add-button !bg-[#686D76]">Add</button>
                                        : <button className="disable-button bg-green-700 text-sm">Confirmed</button>
                                    }
                                    {/* <button onClick={() => handleDisableClick(participant._id)} className="scan-button disable-button">Disable</button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };


    return (
        <div>
            <InputField
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for participants..."
            />
            {renderParticipants()}
        </div>
    );
};

export default DynamicParticipantSearch;
