import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserSpotifyCallback() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state'); // If state is used, ensure to handle it.

        if (!code) {
            console.error('Authorization code is missing');
            // navigate('/error', { state: { error: 'Authorization code is missing from Spotify' }});
            return;
        }

        fetch(`${PORT_BACKEND_URL_SRC}/user-spotify-callback?code=${code}&state=${state}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('authKey') },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to exchange Spotify authorization code');
                }
                return response.json();
            })
            .then(data => {
                if (data.success && data.redirectUrl) {
                    window.location.href = data.redirectUrl; // Use window.location to force a page refresh
                } else {
                    console.error('Spotify authentication error:', data.message);
                    // navigate('/error', { state: { error: data.message }});
                }
            })
            .catch(error => {
                console.error('Error exchanging authorization code for tokens:', error);
                // navigate('/error', { state: { error: error.message || 'Network error or bad response from server' }});
            });
    }, []);

    return (
        <>
            {/*<div>Connecting to Spotify...</div>
    <button className='createform-button mt-8' onClick={() => window.location.reload()}>Refresh</button>*/}
            <div className="flex flex-col dark:bg-[#191a1b] items-center justify-center min-h-screen bg-gray-100 px-4">
                <div className="w-full dark:bg-black dark:text-white max-w-md p-8 space-y-8 bg-white shadow-lg rounded-xl">
                    {/* Image inclusion */}
                    <img
                        src="/images/spotify_logo.png"  // Adjust the path as necessary
                        alt="Spotify Logo"
                        className="mx-auto h-20"  // Set the height, adjust as necessary
                    />
                    <div className="text-center">
                        <h2 className="text-2xl font-bold">Spotify Login</h2>
                        <p className="mt-5 text-center text-sm font-bold text-gray-600 text-left">
                            Not Redirected ? Click on Refresh button
                        </p>
                    </div>
                    <button
                        onClick={() => window.location.reload()}
                        className="w-full py-3 mt-4 font-bold bg-[#686D76] text-white rounded-md"

                    >Refresh
                    </button>
                </div>
            </div>
        </>
    )
}

export default UserSpotifyCallback;
