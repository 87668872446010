import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, useAnimations } from '@react-three/drei';
import { FaceLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';
import * as THREE from 'three';

const Model = ({ rotation, currentAvatar, videoRef, canvasRef }) => {
  const [faceLandmarker, setFaceLandmarker] = useState(null);
  const [runningMode, setRunningMode] = useState("IMAGE");
  const [webcamRunning, setWebcamRunning] = useState(false);
  const [actArray, setActArray] = useState({ eventName: 'Idle', eventExpression: 'Idle' });
  const group = useRef();

  const modelPath = useMemo(() => {
    if (currentAvatar.includes('FEMALE_OUTFIT')) {
      return '/models/blu/ale female default.glb';
    } else if (currentAvatar.includes('FEMALE_UNIFORM')) {
      return '/models/blu/ale new texture.glb';
    } else if (currentAvatar.includes('MALE_OUTFIT')) {
      return '/models/blu/ale 2.glb';
    } else if (currentAvatar.includes('MALE_UNIFORM')) {
      return '/models/blu/ale 2 new texture.glb';
    } 
    return ''; // Fallback in case none match
  }, [currentAvatar]);
  

  const { scene, animations } = useGLTF(modelPath);
  const { actions } = useAnimations(animations, group);

  const rainbowShaderMaterial = useMemo(() => new THREE.ShaderMaterial({
    uniforms: {
      mRefractionRatio: { value: 0.96 },
      mFresnelBias: { value: 0.05 },
      mFresnelPower: { value: 2.0 },
      mFresnelScale: { value: 0.5 },
      u_ambient: { value: new THREE.Color(0x000000) },
      u_lightPos: { value: new THREE.Vector3(-600, 0, 400) },
      u_lightCol: { value: new THREE.Color(0xffffff) },
      u_lightIntensity: { value: 0.6 },
    },
    vertexShader: `
      varying vec3 vPos;
      void main() {
        vPos = position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec3 vPos;
      uniform vec3 u_ambient, u_lightPos, u_lightCol;
      uniform float u_lightIntensity;
      void main() {
        float hue = vPos.y * 0.1;
        vec3 rainbowColor = clamp(abs(mod(hue * 6.0 + vec3(0.0, 4.0, 2.0), 6.0) - 3.0) - 1.0, 0.0, 1.0);
        vec3 lightDir = normalize(u_lightPos - vPos);
        float diff = max(dot(normalize(vPos), lightDir), 0.0);
        vec3 lighting = u_lightCol * u_lightIntensity * diff;
        gl_FragColor = vec4(mix(rainbowColor, lighting, 0.5), 1.0);
      }
    `,
  }), []);

  useEffect(() => {
    const createFaceLandmarker = async () => {
      const resolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");
      const landmarker = await FaceLandmarker.createFromOptions(resolver, {
        baseOptions: {
          modelAssetPath: "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
          delegate: "GPU"
        },
        outputFaceBlendshapes: true,
        runningMode: "IMAGE",
        numFaces: 1
      });
      setFaceLandmarker(landmarker);
    };
    createFaceLandmarker();
  }, []);

  useEffect(() => {
    if (faceLandmarker && !webcamRunning) enableCam();
  }, [faceLandmarker, webcamRunning]);

  const enableCam = async () => {
    if (!faceLandmarker) return;
    setWebcamRunning(true);
    // const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    // videoRef.current.srcObject = stream;
    // let frameCount = 0;
    // const predict = () => {
    //   if (videoRef.current?.readyState >= 2) {
    //     if (frameCount % 3 === 0) {
    //       predictWebcam();
    //     }
    //     frameCount++;
    //   }
    //   requestAnimationFrame(predict);
    // };
    // videoRef.current.addEventListener("loadeddata", predict);
    const constraints = { video: true };
    await navigator?.mediaDevices?.getUserMedia(constraints).then((stream) => {
      // if(videoRef.current){
         // @ts-ignore
      videoRef.current.srcObject = stream;
      // @ts-ignore
      videoRef.current.addEventListener("loadeddata", predictWebcam); 
      // }
    });


    setInterval(async () => {
      await navigator?.mediaDevices
        ?.getUserMedia(constraints)
        .then((stream) => {
          if(videoRef.current){
            // @ts-ignore
          videoRef.current.srcObject = stream;
          // @ts-ignore
          videoRef.current.addEventListener("loadeddata", predictWebcam); 
          }
        });
    }, 30);
  };

  const predictWebcam = async () => {
    const ctx = canvasRef.current.getContext("2d");
    const drawingUtils = new DrawingUtils(ctx);
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if (runningMode !== "VIDEO") {
      await faceLandmarker.setOptions({ runningMode: "VIDEO" });
      setRunningMode("VIDEO");
    }

    const results = await faceLandmarker.detectForVideo(videoRef.current, performance.now());
    if (results.faceLandmarks) {
      results.faceLandmarks.forEach(landmarks => {
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, { color: "#C0C0C070", lineWidth: 1 });
      });
    }

    drawBlendShapes(results.faceBlendshapes);

    if (webcamRunning) {
      setTimeout(predictWebcam, 100);
    }
  };

  const drawBlendShapes = (blendShapes) => {
    if (!blendShapes.length) return;
    blendShapes[0].categories.forEach(shape => {
      if(shape.categoryName === 'eyeWideLeft' && shape.score >= 0.01 || shape.categoryName === 'eyeWideRight' && shape.score >= 0.01) {
        // setActArray({ eventExpression: 'dance 2 baked', eventName: 'mouthUpperUpLeft' });
        if (currentAvatar.includes('FEMALE')) {
          setActArray({ eventExpression: 'dance 2 baked', eventName: 'mouthUpperUpLeft' });
        }
        else{
          setActArray({ eventExpression: 'dance', eventName: 'mouthUpperUpLeft' });
        }
      } else if (shape.categoryName === 'mouthUpperUpLeft' && shape.score >= 0.2) {
        if (currentAvatar.includes('FEMALE')) {
          setActArray({ eventExpression: 'dance 1 baked', eventName: 'mouthUpperUpLeft' });
        }
        else{
          setActArray({ eventExpression: 'happy', eventName: 'mouthUpperUpLeft' });
        }
        // setActArray({ eventExpression: 'dance 1 baked', eventName: 'mouthUpperUpLeft' });
      } else if (shape.categoryName === 'jawOpen' && shape.score >= 0.01) {
        // setActArray({ eventExpression: 'sad depressio', eventName: 'jawOpen' });
        if (currentAvatar.includes('FEMALE')) {
          setActArray({ eventExpression: 'sad depressio', eventName: 'mouthUpperUpLeft' });
        }
        else{
          setActArray({ eventExpression: 'sad', eventName: 'mouthUpperUpLeft' });
        }
      } else if (shape.categoryName === 'mouthClose' && shape.score <= 0.0001) {
        setActArray({ eventExpression: 'bored', eventName: 'mouthClose' });
      }
    });
  };

  const modifiedRotation = [0.6, rotation.y * 0.03, 0];
  useEffect(() => {
    if (actions) {
      // Stop the current action if it exists
      Object.keys(actions).forEach(actionName => {
        if (actions[actionName].isRunning()) {
          actions[actionName].stop();
        }
      });
  
      // Check if the new action exists and play it
      if (actions[actArray.eventExpression]) {
        actions[actArray.eventExpression].play().setLoop(THREE.LoopRepeat, Infinity);
      }
    }
  }, [actions, actArray.eventExpression]);
  

  return (
    <group ref={group} scale={[2.6, 2.6, 2.6]} rotation={modifiedRotation} position={[0, -3, 0]}>
      <primitive object={scene} />
      {/* {currentAvatar.includes('MONOCOLOR') && scene.traverse(child => {
        if (child.isMesh) child.material = rainbowShaderMaterial;
      })} */}
    </group>
  );
};

const RotatingModel = ({ rotation, currentAvatar }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  
  return (
    <>
      <video style={{ display: "none" }} ref={videoRef} autoPlay />
      <canvas style={{ display: "none" }} ref={canvasRef} className="output_canvas" width="640" height="480" />
      <Canvas style={{ width: '100vw', height: '100vh', zIndex: -10 }}>
        <ambientLight intensity={1} />
        <pointLight position={[10, 10, 10]} />
        <Model rotation={rotation} videoRef={videoRef} canvasRef={canvasRef} currentAvatar={currentAvatar} />
      </Canvas>
    </>
  );
};

export default RotatingModel;
