import React, { useState, useEffect } from 'react';
import InputField from '../miscBlocks/inputField'; // Ensure the path is correct

const DynamicDocumentSearch = ({ performSearch, handleSelectDocument }) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (performSearch) {
      performSearch(searchTerm);
    }
  }, [searchTerm, performSearch]);


  return (
    <div>
      <InputField
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search documents..."
      />
      {/* The search results are expected to be managed and rendered by the parent component */}
    </div>
  );
};

export default DynamicDocumentSearch;
