import React, { useState } from 'react'
import InputField from '../miscBlocks/inputField'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'

const UserAddBankAccountPopup = ({ setAccountPopup, userBankDetails, setResponse }) => {
    const [addLoader, setAddLoader] = useState(false)
    const secret_key = `Bearer ${process.env.REACT_APP_STRIPE_KEY}`
    const [accountDetails, setAcoountDetails] = useState({
        accountNumber: '',
        accountHolderName: '',
        rountingNumber: '',
        accountHolderType: ''
    })
    const handleInput = (e) => {
        const { name, value } = e.target
        setAcoountDetails({ ...accountDetails, [name]: value })
    }

    const allAccountTypes = ['Individual', 'Company']

    const handlesubmit = (e) => {
        setAddLoader(true)
        const formData = new URLSearchParams();
        formData.append('bank_account[country]', 'US');
        formData.append('bank_account[currency]', 'usd');
        formData.append('bank_account[account_holder_name]', accountDetails.accountHolderName);
        formData.append('bank_account[account_holder_type]', accountDetails.accountHolderType);
        formData.append('bank_account[routing_number]', accountDetails.rountingNumber);
        formData.append('bank_account[account_number]', accountDetails.accountNumber);
        const addStripeBank = async () => {
            const response = await fetch(`https://api.stripe.com/v1/tokens`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: secret_key,
                },
                body: formData.toString(),
            });

            return response
        }
        addStripeBank().then(async (response) => {
            const result = await response.json()
            const stripeToken = result?.id
            const addBankAccount = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/add-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify({ bankToken: stripeToken })
            })
            const jsonData = await addBankAccount.json()
            setResponse({response: addBankAccount, data: jsonData})
            if(!addBankAccount.ok){
                alert(jsonData?.message)
            }
                // const addBankResult = resres.json()
            setAddLoader(false)
            setAccountPopup(false)
            window.location.reload()
        }
        )
        e.preventDefault()
    }
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Add Bank Account
                        </h3>
                        <button type="button" onClick={() => setAccountPopup(false)} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 pt-1">
                        <form className="space-y-4" action="#">
                            <InputField
                                label="Account Number"
                                type="number"
                                value={accountDetails.accountNumber}
                                placeholder="Enter your Account Number"
                                name='accountNumber'
                                onChange={handleInput}
                            />
                            <InputField
                                label="Account Holder Name"
                                type="text"
                                value={accountDetails.accountHolderName}
                                placeholder="Enter your Account Holder Name"
                                name='accountHolderName'
                                onChange={handleInput}
                            />
                            <InputField
                                label="Routing Number"
                                type="number"
                                value={accountDetails.rountingNumber}
                                placeholder="Enter your Routing Number"
                                name='rountingNumber'
                                onChange={handleInput}
                            />
                            <div className='card'>
                                    <label className="text-lg font-medium">
                                        Account Type
                                    </label>
                                    <select
                                        // disabled={true}
                                        value={accountDetails.accountHolderType}
                                        onChange={handleInput}
                                        name='accountHolderType'
                                        className="shadow border dark:bg-black dark:text-white dark:border-gray-800 rounded p-4 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    >
                                        {allAccountTypes.map((type) => <option className='capitalize' value={type.toLocaleLowerCase()}>
                                            {type}
                                        </option>)}
                                    </select>
                            </div>
                            <button type="submit" disabled={addLoader} onClick={handlesubmit} className="w-full !bg-[#686D76] py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center">{!addLoader ? "Add" : "Loading..."}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAddBankAccountPopup
