import React, { useEffect, useRef, useState } from 'react'
import { TypeAnimation } from 'react-type-animation';
import Chat from '../../../components/miscBlocks/chat';
import LoadingData from '../../../components/loading/Loading';
import Avatar from '../../../components/interactiveAvatar/avatar';
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';
import { useNavigate } from 'react-router-dom';
import SpotifyLinkPreviewer from '../../../components/previews/spotifyLinkPreviewer';
import YoutubeChatPreview from '../../../components/previews/youtubechatPreview';
import { handleAnalytics, handleFreeChat, handleLogout, handleNewsletter, handleSettings } from '../dashboardCreate/user-dashboard-create-utility';
import { getCurrentUser } from '../../../api/authAPI';
import ChatHamburgerMenu from '../../../components/miscBlocks/chatHamburgerMenu';
import { getAvatar } from '../../../api/getAvatar';
import PageLoader from '../../../utils/PageLoader';

const UserFreeChat = () => {

    const youtubeRegex = /https:\/\/(youtu\.be\/\w+|www\.youtube\.com\/watch\?v=[\w-]+)/g;
    const spotifyRegex = /https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?[a-zA-Z0-9=&]+)?/g;
    const [chatTranscriptId, setChatTranscriptId] = useState('')
    const [chatHistory, setChatHistory] = useState([]);
    const [previousData, setPreviousData] = useState(null)
    const [menuVisible, setMenuVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate()
    const [currentAvatar, setCurrentAvatar] = useState('')
    const chatHistoryRef = useRef(null);
    const [responseLoading, setResponseLoading] = useState(false)
    const [response, setResponse] = useState({})
    const [loading, setLoading] = useState(false)
    const [isDoneShowing, setIsDoneShowing] = useState(true)

    const getPrviousChat = async () => {
        setLoading(true)
        try {
            const chatResponse = await fetch(`${PORT_BACKEND_URL_SRC}/fetch-creator-transcript`, {
                method: 'GET',
                headers: { Authorization: localStorage.getItem('authKey') }
            });
            const chatData = await chatResponse.json();
            setLoading(false)
            handleSpotifyStatusCode(chatResponse.status, chatData.message);
            if (chatResponse.status === 200) {
                if (chatData.success) {
                    setPreviousData(chatData?.data?.messages);
                    setChatTranscriptId(chatData?.chatTranscriptId)
                }
            }
        } catch (error) {
            console.error('Error fetching created events:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getPrviousChat();
            const avatarData = await getAvatar()
            setCurrentAvatar(avatarData?.userInfo?.defaultAvatarModel)
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (previousData !== null) {
            const initialBotMessage = {
                type: 'initialbot',
                message: 'Welcome to planner support. If you are not familiar with this please service take a look at this tutorial, just to get you started, this allows you to know your audience... ask something like "what is my audience listening to? " this will only work after you create your first events, the more events the better this chat will be',
                element: <div>Welcome to Planner Support. If you are not familiar with this please service take a <a className='text-blue-600' href={process.env.REACT_APP_REDIRECT_URL}>look at this tutorial</a>, just to get you started, this allows you to know your audience... ask something like "what is my audience listening to? " this will only work after you create your first events, the more events the better this chat will be</div>
            };
            if (previousData.length > 0) {
                setChatHistory(previousData);
            } else {
                setChatHistory([initialBotMessage]);
            }
        }
    }, [previousData]);

    const handleSendMessage = async (userMessage) => {
        try {
            setChatHistory((chatHistory) => [
                ...chatHistory,
                { type: "user", message: userMessage },
            ]);
            setResponseLoading(true)
            setIsDoneShowing(false)
            const response = await fetch(
                `${PORT_BACKEND_URL_SRC}/search-query-for-creator`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json", 'Authorization': localStorage.getItem('authKey') },
                    body: JSON.stringify({ message: userMessage, chatTranscriptId }),
                }
            );
            const jsonData = await response.json
            handleSpotifyStatusCode(response.status, jsonData.message)
            if (!response.ok) {
                alert("Failed to send message");
                setResponseLoading(false)
            }

            const data = await response.json();
            // const aiResponseText = data.data.response.content
            //     .map((item) => item.text)
            //     .join(" ");
            const aiResponseText = data.data.AIResponse
            setChatHistory((chatHistory) => [
                ...chatHistory,
                { type: "bot", message: aiResponseText },
            ]);
            if (aiResponseText) {
                setResponseLoading(false)
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    }

    const renderSocialMediaPreview = (link) => {
        if (link.includes("open.spotify.com")) {
            return <SpotifyLinkPreviewer link={link} />;
        } else if (link.includes("youtube.com")) {
            return <YoutubeChatPreview link={link} />;
        } else {
            return <div className="unsupported-link">Unsupported link type.</div>;
        }
    };

    useEffect(() => {
        const getUser = async () => {
            let users = await getCurrentUser(navigate, localStorage.getItem('authKey'), setResponse, 'dashboard')
            setCurrentUser(users.data)
        }
        getUser()
    }, [])

    return (
        <>{loading ? <PageLoader /> :
            <div className="user-chat-experience-container">
                {/* <div className="w-full z-50">
                    <div className="bg-black text-center text-white py-2 text-sm z-50 dark:bg-gray-600">This model is made by ai model <a className='text-blue-500 hover:underline' target='_blank' href='https://sketchfab.com/3d-models/blu-anime-girl-character-c75ab6b43e1e49da952361ee6b36f0a4'>link</a>, if you would like to submit your model to be part of the website experience contact bbfunai4u@gmail.com</div>
                </div> */}
                <div className='z-50 mt-4 py-10 flex justify-end md:justify-center'>
                    <ChatHamburgerMenu
                        currentUser={currentUser}
                        handleLogout={() => handleLogout(navigate, PORT_BACKEND_URL_SRC)}
                        handleNewsletter={() => handleNewsletter(navigate)}
                        handleAnalytics={() => handleAnalytics(navigate)}
                        handleFreeChat={() => handleFreeChat(navigate)}
                        handleSettings={() => handleSettings(navigate)}
                        setMenuVisible={setMenuVisible}
                        menuVisible={menuVisible}
                        isCreator={true}
                    />
                </div>
                {/* <div className="back-button-wrapper new-skip-btn md:w-3/4 z-50 flex justify-between"> */}
                {/* <button onClick={(e) => {
                    e.stopPropagation()
                    navigate(-1)
                }} className="addBank-button">
                    Back
                </button> */}
                {/* <button onClick={(e) => {
                    e.stopPropagation()
                    navigate('/user-dashboard-create')
                }} className="addBank-button">
                    Dashboard
                </button> */}
                {/* </div> */}
                <div className="avatar-container">
                    {currentAvatar !== '' && <Avatar currentAvatar={currentAvatar} />}
                </div>
                <div className="chat-history-container" ref={chatHistoryRef}>
                    {chatHistory.map((message, index) => (
                        <div key={index} className={`mt-4 ${message.type === "UserMessage" ? "flex justify-end gap-2 " : "flex justify-start flex-col gap-2"}`}>
                            <div
                                key={index}
                                className={`message ${message.type === "user" ? "user-message" : "bot-message"
                                    }`}
                            >
                                {message.type === "user" ? message.message : message.responseType === 'FETCH_API_RESPONSE' ? message.message : message.type == 'initialbot' ? message?.element : <TypeAnimation cursor={false} sequence={[(message.message), () => setIsDoneShowing(true)]} speed={90} />}
                                <div className="flex flex-col gap-3 mt-3">
                                    {message.message &&
                                        [].concat(
                                            message.message.match(youtubeRegex) || [],
                                            message.message.match(spotifyRegex) || []
                                        ).map((newLink, linkIndex) => {
                                            return (
                                                <div key={linkIndex}>
                                                    {renderSocialMediaPreview(newLink)}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    {
                        responseLoading && <div className="flex justify-start flex-col gap-2"><div
                            className='message bot-message'
                        >
                            <LoadingData />
                        </div></div>
                    }
                </div>
                <div className="chat-input-container dark:bg-black">
                    <Chat isDoneShowing={isDoneShowing} responseLoading={responseLoading} onSendMessage={handleSendMessage} />
                </div>
            </div>}</>
    )
}

export default UserFreeChat
