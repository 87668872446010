import React from 'react';

const LoginVideoIntro = () => {
    const handleVideoEnd = (e) => {
        e.currentTarget.play();
    };

    return (
        <div className="flex w-full lg:w-2/3 items-center justify-center bg-black">
            <div style={{
                width: '210%', // Full width
                height: '100vh', // Full viewport height
                display: 'flex', // Use flexbox for centering
                justifyContent: 'center',
                alignItems: 'center',
                overflowY: 'auto', // Allow vertical scrolling
            }}>
                <video
                    style={{
                        maxWidth: '210%', // Limit width to prevent overflow
                        maxHeight: '100%', // Limit height to prevent overflow
                        objectFit: 'cover', // Adjust as needed
                    }}
                    loop
                    muted
                    autoPlay
                    playsInline
                    onEnded={handleVideoEnd}
                >
                    <source src="/videos/introPage.mp4" type="video/mp4" />
                </video>
            </div>
        </div>
    );
}

export default LoginVideoIntro;
