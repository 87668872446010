import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TikTokPreview({ link }) {
    const [embedHtml, setEmbedHtml] = useState('');

    function extractVideoId(link) {
        const regex = /\/video\/(\d+)/;
        const match = regex.exec(link);
        if (match && match[1]) {
          return match[1];
        } else {
          throw new Error('Invalid TikTok video link');
        }
      };

      const newUrl = link.replace('https://www.tiktok.com/t/', 'https://www.tiktok.com/oembed?url=https://www.tiktok.com/@scout2015/video/');

    useEffect(() => {
        async function fetchEmbedCode(url) {
            try {
                const videoId = extractVideoId(url);
                // const response = await fetch(`https://www.tiktok.com/oembed?url=${url}`);
                const response = await axios.get(`https://www.tiktok.com/oembed?url=https://www.tiktok.com/@scout2015/video/${videoId}`);
                setEmbedHtml(response.data.html);
            } catch (error) {
                console.error('Failedeeeeee to fetch TikTok embed code:', error);
            }
        }

        fetchEmbedCode(link);
    }, [link]);

    // Inline styles for the container
    const containerStyles = {
        overflow: 'hidden', // Ensure that the overflowing content is hidden
        position: 'relative', // Position relative for absolute positioning of children
        transform: 'translateY(-140px)', // Move the content up by 50px
        marginBottom: '-50px' // Adjust the margin to offset the space taken by the transformation
    };

    // Render the HTML returned by TikTok's oEmbed API
    return (
        <div style={containerStyles} dangerouslySetInnerHTML={{ __html: embedHtml }} />
        // <iframe
        //         className="spotify-iframe"
        //         title="Spotify Content"
        //         src={embedHtml}
        //         // frameBorder="0"
        //         allowTransparency="true"
        //         allow="encrypted-media">
        //     </iframe>
    );
}

export default TikTokPreview;
