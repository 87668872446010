import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend'
import CancelSubscriptionPopup from '../../../components/popup/cancelSubscriptionPopup'
import SubscriptionViewDetailsPopup from '../../../components/popup/subscriptionViewDetailsPopup'

const UserTransctionDetails = ({ subscriptionData }) => {
    const [popup, setPopup] = useState(false)
    const [viewDetails, setViewDeatails] = useState({
        popup: false,
        data: {}
    })
    const [cancelDetails, setCancelDetails] = useState({})
    const navigate = useNavigate()
    const cancelSubscription = async () => {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/cancel-subscription/${cancelDetails?.stripeSubscriptionId}`, {
            method: 'DELETE',
            headers: {
                //   'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
        })
        const data = await response.json()
        if (response?.ok) {
            setPopup(false)
            window.location.reload();
        }
        else {
            alert(data?.message)
            setPopup(false)
        }
    }
    return (
        <>
            {
                popup && <CancelSubscriptionPopup setPopup={setPopup} cancelSubscription={cancelSubscription} />
            }
            {
                viewDetails?.popup && <SubscriptionViewDetailsPopup viewDetails={viewDetails} setViewDeatails={setViewDeatails}/>
            }
            <div className='card'>
                <div className='flex items-center'>
                    <div className="event-title-container">
                        <h1 className="text-md sm:text-xl font-bold md:event-name m-0">Subscription Details</h1>
                    </div>
                    {/* <div style={{ marginLeft: 'auto' }}>
                        <button
                            type='button'
                            className='addBank-button dark:!text-black  dark:!bg-white dark:opacity-50'
                            onClick={() => setPopup(true)}
                        >
                            Cancel Subscription
                        </button>
                    </div> */}
                </div>
                <hr className='mt-4' />
                <div className='mt-4 max-h-[420px] overflow-y-auto'>
                    {/* <div className='flex items-center mb-7 mt-10'> */}
                    {/* <div className='flex flex-col gap-4'>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Subscription Id</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            {subscriptionData?.stripeSubscriptionId}
                        </div>
                    </div>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Status</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            {subscriptionData?.status}
                        </div>
                    </div>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Start Date</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            {new Date(subscriptionData?.startDate).toLocaleDateString()}
                        </div>
                    </div>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Renewal Date</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            {new Date(subscriptionData?.renewalDate).toLocaleDateString()}
                        </div>
                    </div>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>End Date</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            {new Date(subscriptionData?.endDate).toLocaleDateString()}
                        </div>
                    </div>
                    <div className='flex justify-between mr-2'>
                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Price</div>
                        <div className='text-gray-700 dark:text-white md:text-xl'>
                            ${subscriptionData?.amount}
                        </div>
                    </div>
                    <div className='flex justify-center mt-5'>
                        <button
                            type='button'
                            className='addBank-button dark:!text-black  dark:!bg-white dark:opacity-50'
                            onClick={() => navigate('/transaction-details', { state: { stripeSubscriptionId: subscriptionData?.stripeSubscriptionId } })}
                        >
                            View Details
                        </button>
                    </div>
                </div> */}
                    {/* </div> */}
                    {
                        subscriptionData?.length > 0 &&
                        subscriptionData?.map((item) => {
                            return (
                                <div className='card cursor-pointer' onClick={() => setViewDeatails({popup: true, data: item})}>
                                    <div className='flex justify-between gap-5  mr-2'>
                                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Subscription Id</div>
                                        <div className='text-gray-700 dark:text-white md:text-xl break-all'>
                                            {item?.stripeSubscriptionId}
                                        </div>
                                    </div>
                                    {/* <div className='flex justify-between gap-5  mr-2'>
                                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Start Date</div>
                                        <div className='text-gray-700 dark:text-white md:text-xl'>
                                            {new Date(item?.startDate).toLocaleDateString()}
                                        </div>
                                    </div> */}
                                    <div className='flex justify-between gap-5  mr-2'>
                                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Status</div>
                                        <div className='text-gray-700 dark:text-white md:text-xl'>
                                            {item?.status}
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-4 gap-4'>
                                        <button
                                            type='button'
                                            className={`addBank-button py-2 px-3 !bg-[#686D76] text-sm  ${item?.status === 'InActive' && '!bg-gray-700 cursor-not-allowed'}`}
                                            // disabled={item?.status === 'InActive'}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setCancelDetails(item)
                                                setPopup(true)}}
                                        >
                                            {item?.status === 'InActive' ? 'Cancelled Subscription' : 'Cancel Subscription'}
                                        </button>
                                        <button
                                            type='button'
                                            className='addBank-button !bg-[#686D76] py-2 px-3 text-sm '
                                            // onClick={() => navigate('/transaction-details', { state: { stripeSubscriptionId: item?.stripeSubscriptionId } })}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setViewDeatails({popup: true, data: item})}}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default UserTransctionDetails
