import { handleSpotifyStatusCode } from "../../../statuscodemanage/checkSpotifyStatus";
import { removeLocalStorage } from "../../../utils/removeLocalstorage";

/**
 * Fetches user data and events from the backend.
 * @param {Function} setCurrentUser - Function to set the current user.
 * @param {Function} setEvents - Function to set the events.
 * @param {Function} setIsLoading - Function to set the loading state.
 * @param {Function} navigate - The navigate function from react-router-dom.
 * @param {string} PORT_BACKEND_URL_SRC - Backend URL source.
 */
export async function fetchUserDataAndEvents(setCurrentUser, setEvents, setIsLoading, navigate, PORT_BACKEND_URL_SRC, setResponse) {
    setIsLoading(true);
    try {
        const eventsResponse = await fetch(`${PORT_BACKEND_URL_SRC}/api/creator-event-list`, {
            method: 'GET',
            // credentials: 'include',
            headers: {
                'Authorization': localStorage.getItem('authKey')
            },
        });
        const eventsData = await eventsResponse.json();
        handleSpotifyStatusCode(eventsResponse.status, eventsData.message)
        setResponse({ response: eventsResponse, data: eventsData })
        setIsLoading(false);
        if (eventsResponse.status === 211) {
            alert(eventsData?.message)
            removeLocalStorage()
            navigate('/')
        }
        if (eventsData.success) {
            setEvents(eventsData.events);
        } else {
            console.error('No events found or not authorized');
        }
    } catch (error) {
        console.error('Error fetching created events:', error);
    }
}

/**
 * Handles sending the newsletter.
 * @param {Object} newsletterData - Data for the newsletter.
 * @param {string} currentUser - ID of the current user.
 * @param {string} PORT_BACKEND_URL_SRC - Backend URL source.
 */
export async function handleSendNewsletter(newsletterData, currentUser, PORT_BACKEND_URL_SRC) {
    const dataToSend = {
        ...newsletterData,
        creatorId: localStorage.getItem('userId') // This should now be set
    };

    const formData = new FormData();


    formData.append('title', newsletterData.title);
    formData.append('content', newsletterData.content);
    formData.append('newsLetterImage', newsletterData.imageFile);
    formData.append('timePeriod', newsletterData.timePeriod);
    // newsletterData.selectedEvents.forEach(id => {
    //     const event = events.find(e => e._id === id);
    //     if (event) {
    //         formData.append('selectedEvents[]', event._id);
    //     }
    // });
    // formData.append('selectedEvents[]', newsletterData.selectedEvents);
    newsletterData.selectedEvents.forEach(id => {
        formData.append('selectedEvents', id);
    });
    formData.append('messageType', newsletterData.messageType);
    formData.append('creatorId', localStorage.getItem('userId'));

    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/send-newsletter`, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            // body: JSON.stringify(dataToSend),
            body: formData
        });
        const responseData = await response.json();

        if (response.ok && responseData.success) {
            alert('Newsletter sent successfully!');
            // Additional actions on successful newsletter sending can be added here
        } else {
            console.error('Failed to send newsletter:', responseData.message);
            alert(`Failed to send newsletter: ${responseData.message}`);
        }
    } catch (error) {
        console.error('Error sending newsletter:', error);
        alert('An error occurred while sending the newsletter.');
    }
}
