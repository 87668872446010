import '../../../styles/sharedStyle.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import VerificationSMS from '../../../components/authBlocks/verificationSMS';
import { getUserPhone } from '../../../api/phoneAPI';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');


function UserVerifySMSLogin() {
    const [verificationCode, setVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userPhoneNumber, setUserPhoneNumber] = useState(null); // State to store the user's phone number
    const navigate = useNavigate();
    const location = useLocation();
    // const { userId } = useParams(); // Retrieve the userId from the URL

    useEffect(() => {
        // Fetch the user's phone number when the component mounts
        getUserPhone()
            .then(phoneNumber => {
                setUserPhoneNumber(phoneNumber);
            })
            .catch(error => {
                console.error('Error fetching user phone number:', error);
            });
    }, []);

    const handleVerification = () => {
        setIsLoading(true);

        const eventId = localStorage.getItem('eventId');
        const verificationUrl = eventId
            ? `${PORT_BACKEND_URL_SRC}/verify-login-code?usedFor=SPOTIFY_AUTH_CHECK`
            : `${PORT_BACKEND_URL_SRC}/verify-login-code`;

        fetch(verificationUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                verificationCode: verificationCode,
                rememberMe: localStorage.getItem('rememberMe') === "true" ? true : false
            })
        })
            .then(response => {
                if (response.status === 234) {
                    return response.json().then(message => {
                        alert(message.message);
                        navigate('/get-all-referral-code');
                    });
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                if (data.success) {
                    localStorage.removeItem('rememberMe');
                    if (data.data.role === 'creator') {
                        navigate('/user-free-chat');
                    } else {
                        if (eventId) {
                            navigate(`/user-chat-experience/${eventId}`);
                        } else {
                            navigate('/user-discovery');
                        }
                    }
                } else {
                    alert('Verification failed:', data.message);
                }
            })
            .catch(error => {
                console.error('Error during verification:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };



    const handleResendSMS = async () => {
        try {
            if (!userPhoneNumber) {
                alert('User phone number is not available. Cannot resend SMS.');
                return;
            }

            const response = await fetch(`${PORT_BACKEND_URL_SRC}/resend-sms`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber: userPhoneNumber })
            });

            if (!response.ok) {
                throw new Error('Failed to resend SMS');
            }

            //const data = await response.json();
            alert('SMS verification code has been resent.');
        } catch (error) {
            console.error('Error during SMS resend:', error);
            alert(error.message);
        }
    };
    return (
        <div className="flex full-screen">
            <div className="m-auto">
                <VerificationSMS
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    handleVerification={handleVerification}
                    handleResendSMS={handleResendSMS}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}

export default UserVerifySMSLogin;
