import React, { useEffect, useRef, useState } from 'react';
import Avatar from '../../components/interactiveAvatar/avatar';
import Chat from '../../components/miscBlocks/chat';
import { TypeAnimation } from 'react-type-animation';
import LoadingData from '../../components/loading/Loading';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import EventCard from './event-card';
import { useNavigate } from 'react-router-dom';
import PremiumAccountPopup from '../../components/popup/PremiumAccountPopup';
import WelcomeEventDiscoveryPopup from '../../components/popup/WelcomeEventDiscoveryPopup';
import ChatHamburgerMenu from '../../components/miscBlocks/chatHamburgerMenu';
import { handleAnalytics, handleFreeChat, handleLogout, handleNewsletter, handleSettings } from '../user/dashboardCreate/user-dashboard-create-utility';
import { getCurrentUser } from '../../api/authAPI';
import { getAvatar } from '../../api/getAvatar';
import PageLoader from '../../utils/PageLoader';
import SpotifyLinkPreviewer from '../../components/previews/spotifyLinkPreviewer';

const UserDiscovery = () => {
    const navigate = useNavigate()
    const spotifyRegex = /https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?[a-zA-Z0-9=&]+)?/g;
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(false)
    const [responseLoading, setResponseLoading] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [menuVisible, setMenuVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentAvatar, setCurrentAvatar] = useState('')
    const chatEndRef = useRef(null); // Ref for scrolling to the end
    const [chatTranscriptId, setChatTranscriptId] = useState('')
    const [isFirstMessage, setIsFirstMessage] = useState(true)
    const [profilePic, setProfilePic] = useState("")
    const [response, setResponse] = useState({})
    const [isDoneShowing, setIsDoneShowing] = useState(true)
    const [model, setModel] = useState({
        isFirstVisit: false,
        message: '',
        isFreemuimModelExpired: false
    })

    const initialBotMessage = {
        type: "initialbot",
        message: "Get the event nearby your location: ",
        element: <div>Welcome to planner support. if you are not familiar with this service Please take a <a className='text-blue-600' href={process.env.REACT_APP_REDIRECT_URL}>look at this tutorial</a>, just to get you started, this allows you to know the music scene around you... ask something like "do you know any party I should get to? " </div>,
        responseType: "FROM_LOCAL"
    };

    const extractEvent = (inputString) => {
        if (!isFirstMessage) {
            const jsonString = inputString?.match(/\[.*\]/s);
            return JSON.parse(jsonString);
        }
    };

    const apiExtractEvent = (inputString) => {
        const jsonString = inputString?.match(/\[.*\]/s);
        return JSON.parse(jsonString);
    }

    useEffect(() => {
        const getUser = async () => {
            let users = await getCurrentUser(navigate, localStorage.getItem('authKey'), setResponse, 'dashboard')
            setCurrentUser(users.data)
        }
        getUser()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const avatarData = await getAvatar()
            setCurrentAvatar(avatarData?.userInfo?.defaultAvatarModel)
        };
        fetchData();
    }, [])
    
    const renderSocialMediaPreview = (link) => {
        if (link.includes("open.spotify.com")) {
            console.log({link})
          return <SpotifyLinkPreviewer link={link} />;
        } else {
          return <div className="unsupported-link">Unsupported link type.</div>;
        }
      };

    const removeArray = (originalString) => {
        if (!isFirstMessage) {
            // Remove the Spotify preview link using the regex
            // const spotifyRegex = /https:\/\/open\.spotify\.com\/track\/[A-Za-z0-9]+/g;
            // let modifiedString = originalString?.replace(spotifyRegex, '').trim();

            // Remove the array part, if present
            const arrayStart = originalString?.indexOf('[');
            const arrayEnd = originalString?.indexOf(']') + 1; // Include the closing bracket

            if (arrayStart === -1 || arrayEnd === -1) {
                // No brackets found, return the string without Spotify link
                return originalString;
            }

            // Slice out the parts of the string before and after the array
            const beforeArray = originalString?.slice(0, arrayStart).trim();
            const afterArray = originalString?.slice(arrayEnd).trim();

            // Concatenate and return the final modified string
            return beforeArray + ' ' + afterArray;
        }
    }


    const apiRemoveArray = (originalString) => {
        const modifiedString = originalString?.replace(/EventList: \[.*\]/s, '');
        // const arrayStart = originalString?.indexOf('[');
        // const arrayEnd = originalString?.indexOf(']') + 1; // Include the closing bracket

        // if (arrayStart === -1 || arrayEnd === -1) {
        //     // No brackets found, return the original string
        //     return originalString;
        // }

        // // Slice out the parts of the string before and after the array
        // const beforeArray = originalString.slice(0, arrayStart).trim();
        // const afterArray = originalString.slice(arrayEnd).trim();

        // // Concatenate and return the modified string
        // return beforeArray + ' ' + afterArray;
        return modifiedString
    }

    useEffect(() => {
        setLoading(true)
        const getChatTranscript = async () => {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/fetch-user-event-discovery-transcript`, {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('authKey')
                }
            });
            const data = await response.json();
            setLoading(false)
            setChatTranscriptId(data?.data?.chatTranscriptId)
            setProfilePic(data?.data?.userProfilePic)
            const previousTranscript = []
            setModel({ isFreemuimModelExpired: data?.data?.isFreemuimModelExpired, message: data?.data?.message, isFirstVisit: data?.data?.isFirstVisit })
            if (data?.data?.chatTranscript?.length > 0) {
                data?.data?.chatTranscript[0]?.messages?.map((item, index) => {
                    const newData = {
                        type: item.type,
                        message: apiRemoveArray(item?.message),
                        eventList: apiExtractEvent(item?.message),
                        responseType: item?.responseType,
                        isShowLine: data?.data?.chatTranscript[0]?.messages?.length === index + 1
                    }
                    previousTranscript.push(newData)
                })
                setChatHistory(previousTranscript)
            }
            else {
                setChatHistory([initialBotMessage]);
            }
        }
        getChatTranscript()
    }, [])

    // Handles the API call and response processing
    const handleResponse = async (userMessage) => {
        const bodyContent = isFirstMessage
            ? { message: userMessage, messageIndex: 'FIRST_TIME', chatTranscriptId }
            : {
                message: userMessage,
                previousResponse: chatHistory,
                eventList: eventList,
                chatTranscriptId
            };

        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/search-for-event-discovery`, {
                method: 'POST',
                headers: {
                    Authorization: localStorage.getItem('authKey'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyContent)
            });

            const data = await response.json();

            if (response.status === 200 && data.success) {
                setChatTranscriptId(data?.chatTranscriptId)
                if (isFirstMessage) {
                    setTimeout(() => {
                        setIsFirstMessage(false)
                    }, 1000)
                }
                return data;
            } else {
                alert(data?.message);
            }
        } catch (error) {
            console.error('Error fetching created events:', error);
        }
    };

    function removePreviewText(inputString) {
        // Define the text to be removed using a regular expression
        const regex = /https:\/\/open\.spotify\.com\/track\/[A-Za-z0-9]+/g;
        console.log({inputString})
    
        // Replace the matched text with an empty string
        const result = inputString?.replace(regex, '');
    
        return result;
      }

    // useEffect(() => {
    //     setChatHistory([initialBotMessage]);
    // }, []);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    // Handles sending user messages and updating chat history
    const handleSendMessage = async (userMessage) => {
        setChatHistory(prevChatHistory => [
            ...prevChatHistory,
            { type: "user", message: userMessage },
        ]);
        setResponseLoading(true);
        const aiResponse = await handleResponse(userMessage);
        setResponseLoading(false);
        setIsDoneShowing(false)
        if (isFirstMessage) {
            setEventList(apiExtractEvent(aiResponse?.AIResponse) || []);
        }
        setChatHistory(prevChatHistory => [
            ...prevChatHistory,
            {
                type: "bot",
                message: apiRemoveArray(aiResponse?.AIResponse) || aiResponse?.message,
                eventList: apiExtractEvent(aiResponse?.AIResponse) || aiResponse?.eventList,
                responseType: "FROM_LOCAL"
            },
        ]);
    };

    function getCurrentDateTime() {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = now.getFullYear();

        let hours = now.getHours();
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? String(hours).padStart(2, '0') : '12'; // Adjust for 12:00 PM/AM

        return `${year}.${month}.${day}, ${hours}.${minutes} ${ampm}`;
    }

    return (
        <>
            {
                loading ? <PageLoader /> :
                    <>
                        {
                            model?.isFirstVisit && <WelcomeEventDiscoveryPopup message={model?.message} setModel={setModel} model={model} />
                        }
                        {
                            model?.isFreemuimModelExpired && <PremiumAccountPopup message={model?.message} setModel={setModel} model={model} />
                        }
                        <div className="user-chat-experience-container">
                            <div className='z-50 mt-4 py-10 flex justify-end md:justify-center'>
                                <ChatHamburgerMenu
                                    currentUser={currentUser}
                                    handleLogout={() => handleLogout(navigate, PORT_BACKEND_URL_SRC)}
                                    handleNewsletter={() => handleNewsletter(navigate)}
                                    handleAnalytics={() => handleAnalytics(navigate)}
                                    handleFreeChat={() => handleFreeChat(navigate)}
                                    handleSettings={() => handleSettings(navigate)}
                                    setMenuVisible={setMenuVisible}
                                    menuVisible={menuVisible}
                                    isCreator={false}
                                />
                            </div>
                            {/* <div className="back-button-wrapper new-skip-btn md:w-3/4 z-50 flex justify-between"> */}
                            {/* <button onClick={(e) => {
                    e.stopPropagation()
                    navigate(-1)
                }} className="addBank-button">
                    Back
                </button> */}
                            {/* <button onClick={(e) => {
                        e.stopPropagation()
                        navigate('/user-dashboard-book')
                    }} className="addBank-button">
                        Dashboard
                    </button> */}
                            {/* </div> */}
                            <div className="avatar-container">
                                {currentAvatar !== '' && <Avatar currentAvatar={currentAvatar} />}
                            </div>
                            <div className="chat-history-container">
                                {chatHistory.map((message, index) => (
                                    <>
                                        <div key={index} className={`mt-4 flex ${message.type === "user" ? "justify-end gap-2 " : "justify-end flex-row-reverse gap-2"}`}>
                                            <div className={`message ${message.type === "user" ? "user-message" : "bot-message"}`}>
                                                {message.type === "user"
                                                    ? message.message
                                                    : message?.responseType === "FROM_LOCAL" ? message?.type === "initialbot" ? message?.element : <TypeAnimation cursor={false} sequence={[(removePreviewText(message?.message)), () => setIsDoneShowing(true)]} speed={90} /> : removePreviewText(message?.message)
                                                }
                                                {message.type === "bot" && message.eventList?.length > 0 && (
                                                    message?.eventList.map(item => (
                                                        <EventCard key={item.eventId} event={item} />
                                                    ))
                                                )}
                                                {message?.message &&
                                                    [].concat(
                                                        message?.message?.match(spotifyRegex) || []
                                                    ).map((newLink, linkIndex) => {
                                                        return (
                                                            <div key={linkIndex} className='mt-2'>
                                                                {renderSocialMediaPreview(newLink)}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <img className='w-8 h-8 rounded-full' src={message.type === "user" ? PORT_BACKEND_URL_SRC + "/" + profilePic : "/images/ai_bot_image.jpeg"} alt='Profile Pic' />
                                        </div>
                                        {message?.isShowLine &&
                                            <div className='flex justify-center items-center my-6'>
                                                <div className='w-[33%] md:w-[41%] h-[1px] bg-gray-600 dark:bg-gray-300'></div>
                                                {/* <div className='px-2 text-gray-600 dark:text-gray-300'>Any time that the page is refreshed, the conversation will restart</div> */}
                                                <div className='px-2 text-gray-600 dark:text-gray-300'>{getCurrentDateTime()}</div>
                                                <div className='w-[33%] md:w-[41%] h-[1px] bg-gray-600 dark:bg-gray-300'></div>
                                            </div>
                                        }
                                    </>
                                ))}

                                {responseLoading && (
                                    <div className="flex justify-start flex-col gap-2">
                                        <div className="message bot-message">
                                            <LoadingData />
                                        </div>
                                    </div>
                                )}

                                <div ref={chatEndRef} /> {/* This empty div helps to scroll to the bottom */}
                            </div>

                            <div className="chat-input-container dark:bg-black">
                                <Chat isDoneShowing={isDoneShowing} responseLoading={responseLoading} onSendMessage={handleSendMessage} />
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default UserDiscovery;
