import React, { useRef, useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageCropPopup = ({ file, onImageDrop, setPopup, setCroppedImage }) => {
    // const [croppedImage, setCroppedImage] = useState('');
    const cropperRef = useRef(null);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setImageUrl(file);
    }, [file]);

    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        const croppedCanvas = cropper.getCroppedCanvas();

        // Convert the canvas to a Blob
        croppedCanvas.toBlob((blob) => {
            if (blob) {
                // Create a File object from the Blob
                const file = new File([blob], 'cropped_image.jpg', {
                    type: 'image/jpeg',
                });
                setCroppedImage(URL.createObjectURL(blob)); // Set the image URL for display
                setPopup(false)
                onImageDrop(file)
            }
        });
    };

    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-250 justify-center items-center w-full md:inset-0 max-h-full h-screen bg-white">
            <div className="z-250 relative p-4 w-full max-w-lg h-screen">
                <div className="relative bg-white rounded-lg dark:bg-[#191a1b] dark:text-white shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Crop Image
                        </h3>
                        <button type="button" onClick={() => setPopup(false)} className="end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className='md:flex'>
                        <div className="p-4 md:p-5">
                            <div className='card'>
                                <Cropper
                                    // src="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"
                                    src={imageUrl}
                                    style={{ height: '100%', width: '100%', zIndex: 3000 }}
                                    // Cropper.js options
                                    initialAspectRatio={1}
                                    guides={false}
                                    aspectRatio={1}
                                    ref={cropperRef}
                                />
                                <div className='cursor-pointer w-fit px-6 py-3 bg-[#686D76] text-white rounded ' onClick={onCrop} style={{ marginTop: '10px' }}>
                                    Crop
                                </div>
                                {/* {croppedImage && (
                                    <div>
                                        <h3>Cropped Image:</h3>
                                        <img src={croppedImage} width="200px" alt="Cropped" />
                                    </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageCropPopup
