import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import UserEventDetails from '../regularuser/user-event-details';
import UserPaymentDetails from '../regularuser/user-payment-details';
import QRCode from "react-qr-code";
import NotFound from '../NotFound';
import PageLoader from '../../utils/PageLoader';

const GuestBookingDetails = () => {
    const [eventData, setEventData] = useState({});
    const searchParams = new URLSearchParams(useLocation().search);
    const bookingTransactionId = searchParams.get('bookingTransactionId')
    const eventId = searchParams.get('eventId')
    const [pageFound, setPageFound] = useState(true)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchEventDetails() {
            try {
                const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/guest-booking-event?eventId=${eventId}&bookingTransactionId=${bookingTransactionId}`, {
                    method: 'GET',
                    // headers: {
                    //     'Authorization': localStorage.getItem('authKey')
                    // }
                });
                const data = await response.json();
                setLoading(false)
                if (response.status === 200) {
                    setPageFound(true)
                    setEventData(data.data[0]);
                }
                else if (response.status === 400) {
                    setPageFound(false)
                    setMessage(data?.message)
                }
                else {
                    alert(data.message)
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        }
        fetchEventDetails();
    }, [])

    const passingData = {
        participantId: eventData?.participantId,
        eventId: eventData?.eventId,
        bookingTransactionId: eventData?.bookingTransactionDetails?._id
    }
    return (
        <>
        {loading ? <PageLoader/> : <>
            {
                !pageFound ? <NotFound message={message}/> :
                    <div className='basic-container'>
                        <UserEventDetails eventDetails={eventData} />
                        <UserPaymentDetails bookingTransactionDetails={eventData.bookingTransactionDetails} />
                        <div
                            style={{
                                height: 'auto',
                                maxWidth: '180px',
                                width: '100%',
                                marginTop: "50px",
                            }}
                            className='bg-white p-1'
                        >
                            <QRCode
                                size={256}
                                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                value={JSON.stringify(passingData)}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className='mt-5 text-lg font-bold'>Note: Use this QR code when you attend the event for quick entry.</div>
                    </div>
            }
        </>}</>
    )
}

export default GuestBookingDetails
